<div class="alert alert-info my-1" *ngIf="isProductDetailPage && !product?.isStock && !product?.isAccessory">
  <span>{{text.nonStockShoeInfo | l}}</span>
</div>

<div *ngIf="product?.hasSizes">
  <div class="size-select" [ngClass]="{
    'eighteen-sizes': numberOfSizes === 18,
    'twenty-sizes': numberOfSizes === 20
  }">

    <div class="form-group item hider" *ngFor="let size of ss[ss.format][product.sizeType]; let i = index"
         [class.disabled]="product.sizes && product.sizes[i].status > 4" [hidden]="size === ''"
         [class.hidden]="size === ''"
         [class.spinner-enabled]="!(product.sizes[i].status > 4) || product.sizes[i].status === 6"
    >
      <div class="hide-checkmark">
        {{size}}
      </div>

      <div class="spinner-buttons disable-text-highlighting">
        <div class="spinner-button regulator-buttons" (click)="onSpinnerChange(-1,i, product)">
          <span class="button">-</span>
        </div>
        <div class="spinner-button regulator-buttons" (click)="onSpinnerChange(1,i, product)">
          <span class="button">+</span>
        </div>
      </div>

      <input #input class="form-control" [ngModel]="product.size[i] | amount"
             (ngModelChange)="product.size[i] = sanitizeInput($event)" [disabled]="product.sizes[i].status > 4 && product.sizes[i].status < 6"
             type="text"
             (blur)="sanitizeInput($event)" (change)="onSizeChanged(product)" (keyup.ENTER)="onSubmit()"
             inputmode="numeric">

      <div class="count" *ngIf="showCount" [ngClass]="{exists: getProductSize(product, collectionName)[i]}">
        {{ getProductSize(product, collectionName)[i] || 0}}
      </div>
      <div class="d-flex justify-content-center availability low" ngbTooltip="{{text.stock.low | l}}" placement="bottom"
           *ngIf="product.sizes[i].status === 2">
        <app-icon>warning</app-icon>
      </div>

      <div class="d-flex justify-content-center availability high" *ngIf="product.sizes[i].status === 1 && product.sizes[i].status < 6">
        <app-icon>done</app-icon>
      </div>

      <div class="d-flex justify-content-center availability mid" *ngIf="product.sizes[i].status === 3"
           placement="bottom" ngbTooltip="{{text.stock.mid | l}}: {{product.sizes[i].available | date: 'dd.MM.yyyy'}}">
        {{product.sizes[i].available | date: 'dd.MM.'}}
      </div>
      <div class="d-flex justify-content-center availability remaining-stock" *ngIf="product.sizes[i].status === 6"
       placement="bottom" ngbTooltip="{{text.stock.extWarehouse | l}} {{dateExtendedWarehouse | date: 'dd.MM.yyyy'}}">
          <app-icon>domain</app-icon>
      </div>
      <div class="d-flex justify-content-center availability remaining-stock" *ngIf="product.sizes[i].status === 4"
       placement="bottom" ngbTooltip="{{text.stock.reaminingStock | l}}">
          <app-icon>warning</app-icon>
      </div>
      <div class="d-flex justify-content-center availability out-of-stock" *ngIf="product.sizes[i].status === 5"
           placement="bottom" ngbTooltip="{{text.stock.outOfStock | l}}">
        <app-icon>clear</app-icon>
      </div>
      <div class="d-flex justify-content-center availability not-in-stock" *ngIf="product.sizes[i].status === -1"
           placement="bottom" ngbTooltip="{{text.stock.notInStock | l}} {{deliveryDate | date: 'dd.MM.yyyy'}}">
        <app-icon>access_time</app-icon>
      </div>
    </div>
  </div>
</div>

<div class="accessory-input" [ngClass]="{'not-cart': !isCart}">
  <input #accessoryInput *ngIf="product && !product?.hasSizes" class="form-control amount-input" type="number"
         [min]="product.minOrderQuantity" (change)="onSizeChanged(product)" [(ngModel)]="product.amount"
         (ngModelChange)="product.amount = sanitizeInput($event); forceMinOrderQuantity();" (keyup.ENTER)="onSubmit()"
         (blur)="forceMinOrderQuantity()" (focus)="forceMinOrderQuantity()" [placeholder]="text.quantity | l">
  <app-icon *ngIf="product?.minOrderQuantity > 1"
            [ngbTooltip]="(text.minQuantity | l) + ': ' + product?.minOrderQuantity">help
  </app-icon>
</div>

<div class="sums" *ngIf="!product.notFoundInDb">
  <div>
    <p class="count" *ngIf="product?.hasSizes">{{text.amount | l}}:
      <span>{{product?.amount}}</span>
    </p>

    <p class="price" *ngIf="product">
      {{text.price | l}}:
      <span>{{product?.totalPrice | currency:'EUR':'symbol':'1.2-2':ls.l}}</span>
    </p>
  </div>

  <div class="showCount" *ngIf="showCount">
    <p class="count">{{text.amountIn | l}} {{collectionName === 'cart' ? (text.cart | l) : collectionName}}:
      <span>{{ getProductAmount(product, collectionName)}}</span>
    </p>

    <p class="price" *ngIf="product">
      {{text.sumIn | l}} {{collectionName === 'cart' ? (text.cart | l) : collectionName}}:
      <span>{{product?.totalPrice * getProductAmount(product, collectionName) | currency:'EUR':'symbol':'1.2-2':ls.l}}</span>
    </p>
  </div>
</div>

