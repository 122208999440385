// VORSICHT! DE und EN sind hier BEWUSST vertauscht. Kurz drüber nachdenken ... macht Sinn!

export const text = {
  title: {
    de: 'Different language detected',
    en: 'Andere Sprache erkannt'
  },
  description: {
    de: 'We detected that your system does not use German. Do you wish to switch to the English version?',
    en: 'Wir haben auf ihrem System die Sprache Deutsch erkannt. Möchten sie zur deutschen Version wechseln?'
  }
};
export const routes = {};
