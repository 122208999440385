import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {LanguageService} from '../../language.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  private activeMainCategory = 'Damen';
  // private activeSubCategory = '';

  public categories = {
    // GERMAN
    de: ['Damen',
      'Damen Latein',
      'Damen Standard',
      'Damen Gardetanz',
      'Damen Trainer',
      'Damen Brautschuhe',
      'Herren',
      'Herren Latein',
      'Herren Standard',
      'Herren Tango',
      'Herren Salsa',
      'Herren Sneaker',
      'Herren Hochzeitsschuhe',
      'Kinder',
      'Knaben',
      'Mädchen'
    ],
    // ENGLISH
    en: ['Ladies',
      'Ladies Latin',
      'Ladies Standard',
      'Ladies Showdance',
      'Ladies Trainer',
      'Ladies Bridal',
      'Mens',
      'Mens Latin',
      'Mens Standard',
      'Mens Tango',
      'Mens Salsa',
      'Mens Sneaker',
      'Mens Wedding',
      'Children',
      'Boys',
      'Girls'
    ]

  };

  private categoriesSolver = {
    // GERMAN
    'damen': '/damen',
    'damen latein': '/damen/latein',
    'damen standard': '/damen/standard',
    'damen gardetanz': '/damen/gardetanz',
    'damen trainer': '/damen/trainer',
    'damen brautschuhe': '/damen/hochzeit',
    'herren': '/herren',
    'herren latein': '/herren/latein',
    'herren standard': '/herren/standard',
    'herren tango': '/herren/salsa-tango',
    'herren salsa': '/herren/salsa-tango',
    'herren sneaker': '/herren/sneaker',
    'herren hochzeitsschuhe': '/herrren/hochzeit',
    'kinder': '/kinder',
    'knaben': '/kinder/knaben',
    'mädchen': '/kinder/maedchen',
    // ENGLISH
    'ladies': '/en/ladies',
    'ladies latin': '/en/ladies/latin',
    'ladies standard': '/en/ladies/standard',
    'ladies showdance': '/en/ladies/showdance',
    'ladies trainer': '/en/ladies/trainer',
    'ladies bridal': '/en/ladies/wedding',
    'mens': '/en/mens',
    'mens latin': '/en/mens/latin',
    'mens standard': '/en/mens/standard',
    'mens tango': '/en/mens/salsa-tango',
    'mens salsa': '/en/mens/salsa-tango',
    'mens sneaker': '/en/mens/sneaker',
    'mens wedding': '/en/mens/wedding',
    'children': '/en/children',
    'boys': '/en/children/boys',
    'girls': '/en/children/girls'
  };

  constructor(private location: Location, private ls: LanguageService) {
  }

  private routes = {
    search: {
      de: 'suche',
      en: 'search'
    },
    mainCategories: {
      ladies: {
        de: 'damen',
        en: 'ladies'
      },
      men: {
        de: 'herren',
        en: 'men'
      },
      new: {
        de: 'neu',
        en: 'new'
      },
      sale: {
        de: 'sale',
        en: 'sale'
      },
      children: {
        de: 'kinder',
        en: 'children'
      },
      favorites: {
        de: 'favoriten',
        en: 'favorites'
      }
    }
  };

  generateLink(searchTerm: string) {
    if (searchTerm !== undefined) {
      if (this.categoriesSolver[searchTerm.toLowerCase()] !== undefined) {
        return this.categoriesSolver[searchTerm.toLowerCase()];
      } else {
        return `/${this.ls.rl}${this.routes.search[this.ls.l]}/` + searchTerm;
      }
    } else {
      return `/${this.ls.rl}${this.routes.search[this.ls.l]}`;
    }
  }

  public getActiveMainCategory() {
    const pathString = this.location.path();
    if (pathString.includes(this.routes.mainCategories.ladies[this.ls.l])) {
      this.activeMainCategory = 'Damen';
      return 'Damen';
    } else if (pathString.includes(this.routes.mainCategories.men[this.ls.l])) {
      this.activeMainCategory = 'Herren';
      return 'Herren';
    } else if (pathString.includes(this.routes.mainCategories.children[this.ls.l])) {
      this.activeMainCategory = 'Kinder';
      return 'Kinder';
    } else if (pathString.includes(this.routes.mainCategories.favorites[this.ls.l])) {
      this.activeMainCategory = 'Favoriten';
      return 'Favoriten';
    }
    return this.activeMainCategory;
  }

  public setActiveMainCategory(category: string) {
    this.activeMainCategory = category;
  }

  public getCategoryHierarchy(categories: string[]) {
    if (categories !== undefined && categories !== null && categories.length > 0 && !categories.includes('...')) {
      const de = (this.ls.l !== 'en');

      // Determine Super
      let superCategory = '';
      if (categories.includes('H01')) {
        superCategory = de ? 'Herren' : 'Mens';
      } else if (categories.includes('D01')) {
        superCategory = de ? 'Damen' : 'Ladies';
      } else if (categories.includes('Z01')) {
        superCategory = de ? 'zubehör' : 'accessories';
      }else {
        superCategory = de ? 'Kinder' : 'Children';
      }

      // Determine Subcategory
      let subCategory = '';
      const categoryShort = categories[1].substr(4, categories[1].length);
      switch (categoryShort) {
        case 'Standa':
          subCategory = 'Standard';
          break;
        case 'Latein':
          subCategory = de ? 'Latein' : 'Latin';
          break;
        case 'Garde':
          subCategory = de ? 'Gardetanz' : 'Showdance';
          break;
        case 'Tango':
          subCategory = 'Salsa-Tango';
          break;
        case 'Trai':
          subCategory = 'Trainer';
          break;
        case 'Ballro':
          subCategory = 'Sneaker';
          break;
        case 'Knab':
          subCategory = de ? 'Knaben' : 'Boys';
          break;
        case 'Maed':
          subCategory = de ? 'Mädchen' : 'Girls';
          break;
      }

      // Check if all went fine
      if (superCategory === '' && subCategory === '') {
        return de ? ['Artikel'] : ['Article'];
      }

      return [superCategory, subCategory];
    }
    return ['...', '...'];
  }

}
