<div class="scanner" appVisible (onVisible)="loadImage()"></div>

<div
  class="item"
  [ngClass]="{ 'item-list': listView }"
  *ngIf="content"
>
  <a
    class="product-image"
    (mouseenter)="showPreviewImage()"
    (mouseleave)="resetToOriginal()"
    (click)="click()"
    [routerLink]="getLink()"
  >
    <div class="image-header">
      <div class="item-link" appVisible (onVisible)="loadImage()">
        <span
          *ngIf="productStatus === 'sale' && loginService.isLoggedIn"
          class="badge badge-danger"
          >{{ text.sale | l }}</span
        >
        <span *ngIf="productStatus === 'neu'" class="badge badge-warning">{{
          text.new | l
        }}</span>
        <div
          *ngIf="productStatus === 'both' && loginService.isLoggedIn"
          class="status-both"
        >
          <span class="badge badge-danger">{{ text.sale | l }}</span>
          <span class="badge badge-warning">{{ text.new | l }}</span>
        </div>
      </div>
      <div
        class="favorite"
        [ngClass]="{ 'is-fav': isFavorite() }"
        (click)="toggleFav($event)"
        *ngIf="loginService.isLoggedIn"
      >
        <div
          [ngbTooltip]="
            favs.productIDs.length < 3
              ? isFavorite()
                ? (text.removeFav | l)
                : (text.addFav | l)
              : ''
          "
        >
          <app-icon class="unhover">{{
            isFavorite() ? "star" : "star_border"
          }}</app-icon>
          <app-icon class="hover">{{
            isFavorite() ? "star_border" : "star"
          }}</app-icon>
        </div>
      </div>
    </div>
    <advanced-image
      *ngIf="enteredViewport"
      class="img-responsive"
      [alt]="content.title | l"
      [cldImg]="itemImage"
    ></advanced-image>
  </a>
  <div class="description">
    <div *ngIf="loginService.isLoggedIn; else b2cItemDesc" class="position-relative description-inner">
      <a class="item-link" (click)="click()" [routerLink]="getLink()">
        <div class="info">
          <span class="mod-no" *ngIf="!isAccessory()">{{
            content.articleNumber
          }}</span>
          <span class="accessory-title" *ngIf="isAccessory()">{{
            content.title | l
          }}</span>
          <span class="price">{{
            content.price | currency : "EUR" : "symbol" : "1.2-2" : ls.l
          }}</span>
        </div>
      </a>
      <div class="props d-flex justify-content-between" (click)="click()" [routerLink]="getLink()">
        <span class="prop" *ngIf="!isAccessory() && content.properties"
          >{{ content?.properties?.heelType | l }} -
          <span appHighlightWidthProp>{{
            content?.properties?.width | l
          }}</span></span
        >
        <app-icon
          *ngIf="isInteractive && !isStock"
          ngbTooltip="{{ nonStockText | l }}"
          placement="left"
          >build
        </app-icon>
        <app-icon
          *ngIf="isExternalWarehouse"
          ngbTooltip="{{ sizeText.extWarehouse | l }}"
          placement="left"
          >domain
        </app-icon>
      </div>
      <div class="d-flex" *ngIf="!isStock && content.properties">
        <span class="prop"
          >{{ content?.properties.color | l }}
          {{ content?.properties.material | l }}</span
        >
      </div>
    </div>
    <div
      class="quick-order"
      *ngIf="isInteractive && listView && enteredViewport"
    >
      <app-order [product]="content"></app-order>
    </div>
    <app-size-display
      *ngIf="!isInteractive"
      [product]="content"
    ></app-size-display>
  </div>
</div>

<ng-template #b2cItemDesc>
  <div class="position-relative description-inner">
    <a class="item-link" [routerLink]="getLink()" (click)="click()"></a>
    <div class="info d-flex align-items-center">
      <span class="mod-no b2c-item-num" *ngIf="!isAccessory()">
        Mod. {{content.articleNumber?.split("-")[0]}}
      </span>
      <span class="accessory-title" *ngIf="isAccessory()">{{
        content.title | l
      }}</span>
      <span class="prop" *ngIf="!isAccessory() && content.properties">
      {{ prop | l }}
      </span>
    </div>
    <hr *ngIf="content.retailPrice || !isStock" class="w-100 mt-2 mb-2">
    <div class="d-flex align-items-center justify-content-between w-100">
      <span *ngIf="content.retailPrice" class="price fw-normal">{{
        content.retailPrice | currency : "EUR" : "symbol" : "1.2-2" : ls.l
      }}</span>
      <span *ngIf="!isStock && content.properties && !content.retailPrice" class="prop">
        {{ content.properties.color | l }}
        {{ content.properties.material | l }}
      </span>
      <app-icon
        class="non-stock-icon"
        *ngIf="isInteractive && !isStock"
        ngbTooltip="{{ nonStockText | l }}"
        placement="left"
        >star</app-icon
      >
    </div>
  </div>
</ng-template>