import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { tap } from "rxjs/operators";
import { LocalStorageService } from "../local-storage.service";
import { LoginService } from "./login/login.service";

@Injectable({
  providedIn: "root",
})
export class ImpersonationService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private ls: LoginService,
    private storage: LocalStorageService
  ) {}

  impersonateUser(email: string) {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });

    return this.http
      .get("/api/impersonate/users/" + email, {
        headers: headers,
        responseType: "text",
      })
      .pipe(
        tap((token) => this.storage.storeRaw("auth", token)),
        tap(() => this.ls.remoteAccountInfo()),
        tap(() => this.router.navigate(["/"]))
      );
  }

  removeImpersonation() {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });

    return this.http
      .get("/api/impersonate", { headers: headers, responseType: "text" })
      .pipe(
        tap((token) => this.storage.storeRaw("auth", token)),
        tap(() => this.ls.remoteAccountInfo()),
        tap(() => this.router.navigate(["/"]))
      );
  }
}
