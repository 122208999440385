import { AfterViewInit, Component, Input } from "@angular/core";
import { Product } from "../../../models/product";
import { AnalyticsService } from "../../analytics.service";
import { LoginService } from "../../user/login/login.service";

@Component({
  selector: "app-product-showcase",
  templateUrl: "./product-showcase.component.html",
  styleUrls: ["./product-showcase.component.scss"],
})
export class ProductShowcaseComponent implements AfterViewInit {
  @Input() products: Product[];
  @Input() title: string;

  constructor(private as: AnalyticsService, private ls: LoginService) {}

  ngAfterViewInit() {
    this.as.addViewItemList(this.products, this.title, this.ls.isLoggedIn);
  }
}
