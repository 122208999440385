import { Component, ViewChild } from "@angular/core";
import { ToastService } from "./toast.service";

@Component({
  selector: "app-toast",
  templateUrl: "./toast.component.html",
  styleUrls: ["./toast.component.scss"],
})
export class ToastComponent {
  @ViewChild("timeIndicator", { static: false }) timeIndicator;

  constructor(public ts: ToastService) {}
}
