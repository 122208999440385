import { CollectionTabsComponent } from "./../collection-tabs/collection-tabs.component";
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { NgbNavChangeEvent } from "@ng-bootstrap/ng-bootstrap";
import { Product } from "../../../models/product";
import { CartService } from "../../cart/cart.service";
import { LanguageService } from "../../language.service";
import { SizeSelectComponent } from "../size/size-select/size-select.component";
import { ToastService } from "../toast/toast.service";
import { text } from "./order.i18n";
import { text as quickOrderText } from "./../../cart/quick-order/quick-order.i18n";

/**
 * WARNING: Be careful when changing logic as QuickOrderComponent inherits from this class (specifically
 * adopting the size array from a cart's item and on submit updating the cart accordingly).
 */
@Component({
  selector: "app-order",
  templateUrl: "./order.component.html",
  styleUrls: ["./order.component.scss"],
})
export class OrderComponent {
  public orderText = text;
  public quickOrderText = quickOrderText;

  private _product: Product = null;

  private _cartProduct: Product;

  private _activeTab: string = "cart";
  private _newCollectionName: string;

  @Input() showTabs = true;
  @Input() live = true;
  @Input() isCheckout = false;
  @Input() isProductDetailPage = false;
  @Output() onAdd = new EventEmitter();
  @ViewChild("sizeSelect", { static: true }) sizeSelect: SizeSelectComponent;
  @ViewChild("collectionTabs", { static: false })
  collectionTabs: CollectionTabsComponent;

  constructor(
    public cartService: CartService,
    public languageService: LanguageService,
    public toastService: ToastService
  ) {}

  @Input() set activeTab(s: string) {
    this._activeTab = s;

    if (this.product) {
      this.updateProduct(this.product, s);
    }
  }

  get product(): Product {
    return this._product;
  }

  @Input() set product(product: Product) {
    // Set empty product if null is passed explicitly (QuickOrderComponent).
    if (product === null) {
      this._product = null;
      return;
    }
    this._product = new Product(product);
    this.updateProduct(this._product, this.activeTab);
  }

  get cartProduct(): Product {
    return this._cartProduct;
  }

  get activeTab(): string {
    return this._activeTab;
  }

  get newCollectionName(): string {
    return this._newCollectionName;
  }

  set newCollectionName(newCollectionName: string) {
    this._newCollectionName = newCollectionName;
  }

  add(product: Product, collection: string) {
    if (this.activeTab === "new-collection") {
      this.collectionTabs.newCollection(this.product);
      collection = this.activeTab;
    }

    if (!this._cartProduct && product.isBuyable) {
      this.cartService.addTo(product, this.cartService.getProducts(collection));

      this.toastService.spawnActionToast(
        this.languageService.t(this.orderText.articleAdded),
        this.languageService.t(this.orderText.toCheckout),
        this.languageService.rl + "checkout",
        6000
      );
    }

    if (this._cartProduct) {
      this.cartService.addTo(product, this.cartService.getProducts(collection));
      let checkoutLink = "checkout";
      if (collection !== "cart") {
        checkoutLink = `checkout/${collection}`;
      }
      this.toastService.spawnActionToast(
        this.languageService.t(this.orderText.articleUpdated),
        this.languageService.t(this.orderText.toCheckout),
        this.languageService.rl + checkoutLink,
        6000
      );
    }

    this.resetSize(this.product);

    this.product = new Product(this.product);

    this.onAdd.emit();
  }

  // beforeChange reacts on tab change, sets new active tab and resets size array
  // if on new collection tab.
  beforeChange($event: string) {
    this.activeTab = $event;
    if (this.activeTab === "new-collection") {
      this.resetSize(this.product);
      if (this._cartProduct) {
        this._cartProduct.size = [];
      }
    }
  }

  resetSize(product: Product) {
    if (product.hasSizes) {
      product.size = [];
    } else {
      product.amount = undefined;
    }
  }

  updateProduct(product: Product, collection: string) {
    if (this.live && this._product) {
      this.resetSize(this._product);

      const products = this.cartService.getProducts(collection);
      if (products) {
        const index = products.findIndex((p) => p.articleNumber === product.articleNumber);
        this._cartProduct = products[index];

        if (this._cartProduct) {
          if (this._cartProduct.hasSizes) {
            for (let i = 0; i < this._cartProduct.size.length; i++) {
              this._product.size[i] = this._cartProduct.size[i];
            }
          } else {
            this._product.amount = this._cartProduct.amount;
          }
        }
      }
    }
  }
}
