import { LoginService } from "./login.service";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { LanguageService } from "../../language.service";
import { catchError, map } from "rxjs/operators";
import { WebsocketService } from "../../websocket.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard {
  constructor(
    private ls: LoginService,
    private router: Router,
    private langS: LanguageService,
    private wss: WebsocketService
  ) {}

  canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.ls.getUser().pipe(
      map((user) => {
        if (!user) {
          this.ls.redirectedUrl = state.url;
          this.router.navigate([this.langS.rl + "login"]);
          return false;
        } else {
          this.wss.connect();
          return true;
        }
      }),
      catchError(() => {
        this.router.navigate([this.langS.rl + "login"]);
        return of(false);
      })
    );
  }
}
