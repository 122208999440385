import { Component } from "@angular/core";
import { OverlayService } from "./overlay.service";

@Component({
  selector: "app-overlay",
  templateUrl: "./overlay.component.html",
  styleUrls: ["./overlay.component.scss"],
})
export class OverlayComponent {
  constructor(public os: OverlayService) {}
}
