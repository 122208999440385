import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { lazyload, placeholder } from "@cloudinary/ng";
import { CloudinaryImages } from "../../cloudinary.service";

@Component({
  selector: "app-image-carousel",
  templateUrl: "./image-carousel.component.html",
  styleUrls: ["./image-carousel.component.scss"],
})
export class ImageCarouselComponent implements OnInit {
  @Input()
  images: CloudinaryImages;

  @Input()
  alt: string;

  _activeId: string;

  @Input()
  set activeId(id: string) {
    if (id === 'video') {
      this._activeId = 'video.jpg';
    } else {
      this._activeId = id;
    }
  }

  get activeId(): string {
    return this._activeId;
  }

  @Output()
  imageSelected = new EventEmitter<string>();

  imageIds: string[] = [];
  private extensionRegEx = /\.[a-z]+$/i;
  private exclusions: string[] = ["video"];
  lazyloadWithPlaceholder = [lazyload(), placeholder()];

  ngOnInit(): void {
    this.imageIds = Object.keys(this.images).filter(
      (img) => !this.exclusions.includes(img)
    );

    this.ensureCorrectOrder(this.imageIds);
  }

  select(imgId: string): void {
    this.imageSelected.emit(this.formatId(imgId));
  }

  private formatId(id: string): string {
    const fileExtension = id.match(this.extensionRegEx);
    if (!fileExtension) {
      return id;
    }
    return id.replace(fileExtension[0], "");
  }

  private ensureCorrectOrder(images: string[]): void {
    const moveIds = ["main", "video.jpg"];

    moveIds.forEach((id) => {
      if (images.includes(id)) {
        const imageIndex = images.findIndex((i) => i === id);
        if (imageIndex >= 0) {
          const image = images.splice(imageIndex, 1)[0];
          images.unshift(image);
        }
      }
    });
  }
}
