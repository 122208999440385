import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { StorelocatorRoutingModule } from "./storelocator-routing.module";
import { StorelocatorComponent } from "./storelocator.component";
import { FormsModule } from "@angular/forms";
import { GoogleMapsModule } from "@angular/google-maps";
import { SharedModule } from "../shared/shared.module";

@NgModule({
  declarations: [StorelocatorComponent],
  imports: [
    CommonModule,
    SharedModule,
    StorelocatorRoutingModule,
    FormsModule,
    GoogleMapsModule,
  ],
  exports: [StorelocatorComponent],
})
export class StorelocatorModule {}
