<div *ngIf="product?.hasSizes">
  <div class="size-select">
    <div class="form-group item">
      <span>{{text.size | l}}</span>
      <hr>
      <span>{{text.amount | l}}</span>
    </div>
    <ng-container *ngFor="let size of ss[ss.format][product.sizeType]; let i = index"
                  [class.disabled]="product.sizes && product.sizes[i].status > 4"
                  [class.hidden]="size === ''">
      <div class="form-group item" *ngIf="product.size[i]">
        <span>{{size}}</span>
        <hr>
        <span>{{product.size[i]}}</span>
      </div>
    </ng-container>
  </div>
</div>

<div class="sums">
  <div>
    <p class="count" *ngIf="product">
      {{text.amount | l}}:
      <span>{{product?.amount}}</span>
    </p>

    <p class="price" *ngIf="product">
      {{text.price | l}}:
      <span>{{product?.totalPrice | currency:'EUR':'symbol':'1.2-2':ls.l}}</span>
    </p>
  </div>
</div>
