import { Component, OnInit } from "@angular/core";
import { SeoService } from "../seo.service";
import { routes, text } from "./landing.i18n";
import { LanguageService } from "../language.service";
import { DirectusService } from "../directus.service";
import { LandingPageTile } from "../shared/interfaces/directus/collections/landing-page-tile.interface";
import { Page } from "../shared/interfaces/directus/enums";
import { ActivatedRoute } from "@angular/router";
import { LoginService } from "../user/login/login.service";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
})
export class LandingComponent implements OnInit {
  public text = text;
  public routes = routes;
  public email: string;

  public tiles: LandingPageTile[] = [];

  orgaStructuredData = {
    "@context": "http://schema.org",
    "@type": "Organization",
    name: "Diamant",
    url: "https://www.diamant.net/",
    logo: "https://www.diamant.net/assets/logo.png",
    sameAs: [
      "https://www.instagram.com/diamantdanceshoes/",
      "https://www.facebook.com/Tanzschuhe",
      "https://twitter.com/tanzschuhe",
    ],
    contactPoint: [
      {
        "@type": "ContactPoint",
        contactOption: "TollFree",
        telephone: "+49-(0)6196-594544",
        contactType: "customer service",
      },
    ],
  };

  websiteStructuredData = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    url: "https://www.diamant.net/",
    potentialAction: {
      "@type": "SearchAction",
      target: "https://www.diamant.net/suche/{search_term_string}",
      "query-input": "required name=search_term_string",
    },
  };

  constructor(
    private seo: SeoService,
    public ls: LanguageService,
    private directus: DirectusService,
    private route: ActivatedRoute,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      if (data["tiles"]) {
        this.loadTiles(data["tiles"]);
      } else {
        this.loginService.$user.subscribe((user) => {
          this.loadTiles(user ? Page.B2B : Page.B2C);
        });
      }
    });

    this.seo.setTitle(this.ls.t(this.text.seoTitle));
    this.seo.setDescription(this.ls.t(this.text.seoDescription));
  }

  loadTiles(page: Page = Page.DANCE_SHOES) {
    this.directus.loadLandingPageTiles(page).subscribe((res) => {
      this.tiles = res;
    });
  }
}
