<div class="mobile-wrapper d-lg-none d-print-none">
  <div class="navbar navbar--mobile">
    <div class="left" *ngIf="!ns.showSearch">
      <div class="categories icon" (click)="os.toggleCategoriesOverlay()">
        <app-icon *ngIf="!os.categoriesOverlay">menu</app-icon>
        <app-icon *ngIf="os.categoriesOverlay">close</app-icon>
      </div>
    </div>
    <div class="center" *ngIf="!ns.showSearch">
      <a [routerLink]=routes.home[langS.l]>
        <img class="logo" alt="diamant Logo" src="assets/logo.png">
      </a>
    </div>
    <div class="right">
      <div class="search-bar" *ngIf="ns.showSearch">
        <app-searchbar></app-searchbar>
      </div>

      <div class="search" *ngIf="!ns.showSearch">
        <div class="icon search-icon" (click)="ns.activateSearch(true)">
          <app-icon>search</app-icon>
        </div>
      </div>

      <div class="blog-notifications" [routerLink]="routes.news[langS.l]" *ngIf="!ns.showSearch && ls.isLoggedIn && ls.unread?.count" >
        <div class="unread-icon">
          <p class="unread-count">{{ ls.unread.count }}</p>
        </div>
      </div>

      <div class="cart" [routerLink]="routes.cart[langS.l]" *ngIf="!ns.showSearch && ls.isLoggedIn">
        <div class="icon cart-icon">
          <app-icon>shopping_cart</app-icon>
          <div class="count" *ngIf="cs.productCount > 0">
            <p class="number">{{cs.productCount}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-loading-bar></app-loading-bar>
</div>

<div class="pre-navbar d-none d-sm-flex" *ngIf="!ls.isLoggedIn">
  <div class="pre-navbar-inner">
    <div class="links d-flex align-items-center">
      <a [routerLink]="routes.storeLocator | rl">{{text.storeLocator | l}}</a>
      <a [routerLink]="routes.onlineShops | rl">{{text.onlineShops | l}}</a>
      <a [routerLink]="routes.catalog | rl">{{text.catalog | l}}</a>
      <a [routerLink]="routes.news | rl">{{text.news | l}}</a>
    </div>
    <div class="login d-flex align-items-center">
      <a [routerLink]="routes.login | rl">{{text.merchantLogin | l}}</a>
      <a class="ausbildung" *ngIf="langS.l === 'de'" [routerLink]="'/ausbildung'">Wir bilden aus!</a>
    </div>
  </div>
</div>

<div class="navbar navbar--desktop d-none d-lg-flex d-print-none">
  <div class="navbar-bg">
    <img src="assets/logo.png" alt="Diamant logo">
  </div>
  <div class="navbar-inner d-flex">
    <div class="top d-flex">
      <div class="left">
        <div class="super-category-nav">
          <ul role="tablist" class="nav">
            <li class="nav-item">
              <a class="nav-link" [routerLink]="langS.rl + routes.ladies.root[langS.l]" [routerLinkActive]="['active']"
                 [ngClass]="{'last-active': catS.getActiveMainCategory() === 'Damen'}"
                 #damen>{{text.ladies[langS.l]}}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="langS.rl + routes.mens.root[langS.l]" [routerLinkActive]="['active']"
                 [ngClass]="{'last-active': catS.getActiveMainCategory() === 'Herren'}" #herren>{{text.men[langS.l]}}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="langS.rl + routes.children.root[langS.l]"
                 [routerLinkActive]="['active']" [ngClass]="{'last-active': catS.getActiveMainCategory() === 'Kinder'}"
                 #kinder>{{text.children[langS.l]}}</a>
            </li>
            <li class="nav-item" *ngIf="ls.isLoggedIn">
              <a class="nav-link" [routerLink]="langS.rl + routes.favs[langS.l]" [routerLinkActive]="['active']"
                 [ngClass]="{'last-active': catS.getActiveMainCategory() === 'Favoriten'}"
                 #favorites>{{text.favs[langS.l]}}</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="center">
        <a [routerLink]=routes.home[langS.l]>
          <img class="logo" alt="diamant Logo" src="assets/logo.png">
        </a>
      </div>

      <div class="right">
        <div class="search-bar" *ngIf="ns.showSearch">
          <app-searchbar></app-searchbar>
        </div>

        <div class="search" *ngIf="!ns.showSearch">
          <div class="icon search-icon" (click)="ns.activateSearch(true)">
            <app-icon>search</app-icon>
          </div>
        </div>

        <div class="account" [routerLink]="getAccountRouting() | async" *ngIf="!ns.showSearch && ls.isLoggedIn">
          <div class="icon account-icon">
            <app-icon>account_circle</app-icon>
          </div>
        </div>

        <div class="blog-notifications" [routerLink]="routes.news[langS.l]" *ngIf="!ns.showSearch && ls.isLoggedIn && ls.unread?.count" >
          <div class="unread-icon">
            <p class="unread-count">{{ ls.unread.count }}</p>
          </div>
        </div>

        <div class="cart" [routerLink]="routes.cart[langS.l]" *ngIf="!ns.showSearch && ls.isLoggedIn">
          <div class="icon cart-icon">
            <app-icon>shopping_cart</app-icon>
            <div class="count" *ngIf="cs.productCount > 0">
              <p class="number">{{cs.productCount}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom d-flex">
      <div class="left">
        <div class="categories-links">
          <ul *ngIf="catS.getActiveMainCategory() === 'Damen'">
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.ladies.latin[langS.l]"
                   [routerLinkActive]="['active']">{{text.latin[langS.l]}}</a></li>
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.ladies.standard[langS.l]"
                   [routerLinkActive]="['active']">{{text.standard[langS.l]}}</a></li>
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.ladies.showdance[langS.l]"
                   [routerLinkActive]="['active']">{{text.showdance[langS.l]}}</a></li>
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.ladies.trainer[langS.l]"
                   [routerLinkActive]="['active']">{{text.trainer[langS.l]}}</a></li>
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.ladies.wedding[langS.l]"
                   [routerLinkActive]="['active']">{{text.bridal[langS.l]}}</a></li>
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.ladies.variospin[langS.l]"
                   [routerLinkActive]="['active']">{{text.variospin[langS.l]}}</a></li>
<!--            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.ladies.comfy[langS.l]"-->
<!--                   [routerLinkActive]="['active']">{{text.comfy[langS.l]}}</a></li>-->
<!--            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.ladies.festive[langS.l]"-->
<!--                   [routerLinkActive]="['active']">{{text.festive[langS.l]}}</a></li>-->
<!--            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.ladies.black[langS.l]"-->
<!--                   [routerLinkActive]="['active']">{{text.black[langS.l]}}</a></li>-->
<!--            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.ladies.silver[langS.l]"-->
<!--                   [routerLinkActive]="['active']">{{text.silver[langS.l]}}</a></li>-->
          </ul>

          <ul *ngIf="catS.getActiveMainCategory() === 'Herren'">
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.mens.standard[langS.l]"
                   [routerLinkActive]="['active']">{{text.standard[langS.l]}}</a></li>
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.mens.latin[langS.l]"
                   [routerLinkActive]="['active']">{{text.latin[langS.l]}}</a></li>
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.mens.tango[langS.l]"
                   [routerLinkActive]="['active']">{{text.tango[langS.l]}}</a></li>
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.mens.ballroom[langS.l]"
                   [routerLinkActive]="['active']">{{text.ballroom[langS.l]}}</a></li>
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.mens.wedding[langS.l]"
                   [routerLinkActive]="['active']">{{text.wedding[langS.l]}}</a></li>
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.mens.variospin[langS.l]"
                   [routerLinkActive]="['active']">{{text.variospin[langS.l]}}</a></li>
<!--            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.mens.casual[langS.l]"-->
<!--                   [routerLinkActive]="['active']">{{text.casual[langS.l]}}</a></li>-->
<!--            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.mens.comfy[langS.l]"-->
<!--                   [routerLinkActive]="['active']">{{text.comfy[langS.l]}}</a></li>-->
<!--            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.mens.wide[langS.l]"-->
<!--                   [routerLinkActive]="['active']">{{text.wide[langS.l]}}</a></li>-->
          </ul>

          <ul *ngIf="catS.getActiveMainCategory() === 'Kinder'">
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.children.boys[langS.l]"
                   [routerLinkActive]="['active']">{{text.boys[langS.l]}}</a></li>
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.children.girls[langS.l]"
                   [routerLinkActive]="['active']">{{text.girls[langS.l]}}</a></li>
          </ul>

          <ul *ngIf="catS.getActiveMainCategory() === 'Neu'">
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.new[langS.l]"
                   [routerLinkActive]="['active']">{{text.new[langS.l]}}</a></li>
          </ul>

          <ul *ngIf="catS.getActiveMainCategory() === 'Sale'">
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.sale[langS.l]"
                   [routerLinkActive]="['active']">{{text.sale[langS.l]}}</a></li>
          </ul>

          <span *ngIf="catS.getActiveMainCategory() !== 'Favoriten' && !isIE()">|</span>

          <span *ngIf="catS.getActiveMainCategory() !== 'Favoriten' && !isIE()"><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.misc.variopro[langS.l]"
                                   [routerLinkActive]="['active']">{{text.variopro[langS.l]}}</a></span>
          <span *ngIf="catS.getActiveMainCategory() !== 'Favoriten' && !isIE()"><a (click)="os.hideCategoriesOverlay()"
                                                                                   [routerLink]="langS.rl + routes.misc.accessories[langS.l]"
                                                                                   [routerLinkActive]="['active']">{{text.accessories[langS.l]}}</a></span>
          <span *ngIf="catS.getActiveMainCategory() !== 'Favoriten' && !isIE() && ls.isLoggedIn"><a (click)="os.hideCategoriesOverlay()"
                                                                                   class="sale"
                                                                                   [routerLink]="langS.rl + routes.sale[langS.l]"
                                                                                   [routerLinkActive]="['active']">{{text.sale[langS.l]}}</a></span>
          <span *ngIf="catS.getActiveMainCategory() !== 'Favoriten' && !isIE()"><a (click)="os.hideCategoriesOverlay()"
                                                                                   [routerLink]="langS.rl + routes.new[langS.l]"
                                                                                   [routerLinkActive]="['active']">{{text.new[langS.l]}}</a></span>
        </div>
      </div>
      <div class="center">
      </div>
      <div class="right">
        <app-size-format-dropdown *ngIf="ls.isLoggedIn"></app-size-format-dropdown>
        <app-language-picker></app-language-picker>
      </div>
    </div>
  </div>
</div>
<div *ngIf="user?.impersonated" class="impersonation-container">
  <p>{{text.impersonationInfo | l}} <b>{{user?.email}}</b></p>
  <app-button
    size="xsm"
    (click)="removeImpersonation()"
    [text]="text.stopImpersonating | l"
  ></app-button>
</div>
<app-loading-bar></app-loading-bar>

<app-overlay class="d-lg-none"></app-overlay>
