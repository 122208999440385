import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Product } from "../../../models/product";
import { CartService } from "../../cart/cart.service";

@Component({
  selector: "app-order-button",
  templateUrl: "./order-button.component.html",
  styleUrls: ["./order-button.component.scss"],
})
export class OrderButtonComponent {
  text = {
    addToCart: {
      de: "In den Warenkorb",
      en: "Add to cart",
    },
    addTo: {
      de: "Hinzufügen zu: ",
      en: "Add to: ",
    },
    createCollection: {
      de: "Merkliste erstellen",
      en: "Create collection",
    },
    missingCollectionName: {
      de: "Bitte Merklistenname vergeben",
      en: "Please provide a collection name",
    },
    moq: {
      de: "Mindestbestellmenge",
      en: "Minimum order quantity",
    },
    products: {
      de: "Artikel",
      en: "product(s)",
    },
    notReached: {
      de: "nicht erreicht",
      en: "not reached",
    },
    updateCart: {
      de: "Warenkorb aktualisieren",
      en: "Update Cart",
    },
    updateList: {
      de: "Liste aktualisieren",
      en: "Update List",
    },
  };

  @Output() add = new EventEmitter<Product>();

  @Input() product: Product;
  @Input() cartProduct: Product;

  @Input() collectionName: string;
  @Input() newCollectionName: string;

  constructor(public cs: CartService) {}

  get isDisabled(): boolean {
    // Check new collection name if on new-collection tab.
    if (
      this.collectionName === "new-collection" &&
      !this.cs.allowedCollectionName(this.newCollectionName)
    ) {
      return true;
    }

    if (this.product && this.cartProduct) {
      if (this.cartProduct.hasSizes) {
        if (!this.product.size) {
          return false;
        }
        let eq = true;
        if (this.product.size.length !== this.cartProduct.size.length) {
          return false;
        }

        for (let i = 0; i < this.product.size.length; i++) {
          if (this.product.size[i] !== this.cartProduct.size[i]) {
            eq = false;
          }
        }

        return eq || !this.product.isBuyable;
      } else {
        return this.product.amount > 0
          ? this.cartProduct.amount === this.product.amount
          : true;
      }
    }
    return this.product && !this.product.isBuyable;
  }
  addProduct(event): void {
    if (!this.isDisabled) {
      this.add.emit(event);
    }
  }
}
