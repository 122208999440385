<div class="thumbnails">
  <div
    class="img-thumbnail"
    *ngFor="let imgId of imageIds; let i = index"
    (click)="select(imgId)"
    (mouseover)="select(imgId)"
    [class.active]="activeId === imgId"
  >
    <advanced-image
      [cldImg]="images[imgId]"
      [plugins]="lazyloadWithPlaceholder"
      [alt]="alt + ' | Thumbnail ' + i"
    ></advanced-image>
  </div>
</div>
