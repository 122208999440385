import { Injectable } from "@angular/core";
import { Product } from "../../../models/product";
import { LocalStorageService } from "../../local-storage.service";
import { LoginService } from "../../user/login/login.service";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SearchService } from "../search.service";

@Injectable({
  providedIn: "root",
})
export class FavoritesService {
  public productIDs: string[] = [];

  cache = false;

  constructor(
    private storage: LocalStorageService,
    private ls: LoginService,
    private http: HttpClient,
    private searchService: SearchService,
  ) {
    this.loadFavorites();
  }

  /**
   * Adds a the product (as id) to the products array
   * @param productID
   */
  public addProduct(productID: string) {
    if (productID) {
      if (!this.productIDs) {
        this.productIDs = [productID];
      } else if (!this.productIDs.includes(productID)) {
        this.productIDs.push(productID);
      } else {
        return;
      }
    }
  }

  public getFavorites(limit?: number, offset?: number): Observable<Product[]> {
    const headers = this.cache
      ? new HttpHeaders({ "Content-Type": "application/json" })
      : new HttpHeaders({
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
        });
    const params = this.searchService.getHttpParams(limit, offset);
    const options = { headers: headers, params: params };
    this.cache = true;

    return this.http.get<Product[]>("/api/users/favs", options);
  }

  public isFavorite(product: Product) {
    if (!this.productIDs) {
      return false;
    }
    return this.productIDs.includes(product.articleNumber);
  }

  public removeFromFavs(product: Product) {
    this.removeProduct(product.articleNumber);
    this.push();
  }

  /**
   * Adds a new product to favs
   * @param product
   */
  public addToFavs(product: Product) {
    this.addProduct(product.articleNumber);
    this.push();
  }

  /**
   * Request API for user favs
   */
  private loadFavorites() {
    this.ls.getUser().subscribe((user) => {
      this.handleFavsLoading(user);
    });
  }

  private removeProduct(productID: string) {
    if (this.productIDs) {
      const index = this.productIDs.indexOf(productID);
      if (index > -1) {
        this.productIDs.splice(index, 1);
      }
    }
  }

  /**
   * If user has online favs -> load it
   * Else load the local favs
   * @param user
   */
  private handleFavsLoading(user) {
    if (user) {
      this.loadOnline(user.favs);
    } else {
      this.unauth();
    }
  }

  private unauth() {
    this.cache = false;
    this.productIDs = [];
    this.storage.store("favs", this.productIDs);
    this.storage.store("online", "");
  }

  /**
   * Load favs from DB
   * @param onlineFavs
   */
  private loadOnline(onlineFavs) {
    if (this.storage.retrieve("online")) {
      this.productIDs = onlineFavs;
    } else {
      this.productIDs = this.storage.retrieve("favs");

      //merge online and offline favs
      if (onlineFavs !== null) {
        for (let i = 0; i < onlineFavs.length; i++) {
          this.addToFavs(onlineFavs[i]);
        }
      }
      this.storage.store("online", true);
    }
  }

  /**
   * Saves the favs to local storage and database
   */
  private push() {
    this.cache = false;
    if (!this.productIDs) {
      this.productIDs = [];
    }
    this.storage.store("favs", this.productIDs);

    if (this.storage.retrieve("online")) {
      this.ls.updateFavorites({ favs: this.productIDs }).subscribe(
        (success) => true,
        (err) => this.unauth()
      );
    } else {
      this.storage.store("online", "");
    }
  }
}
