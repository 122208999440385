export const text = {
  ladies: {
    de: 'Damen',
    en: 'Ladies'
  },
  men: {
    de: 'Herren',
    en: 'Men'
  },
  children: {
    de: 'Kinder',
    en: 'Children'
  },
  latin: {
    de: 'Latein',
    en: 'Latin'
  },
  standard: {
    de: 'Standard',
    en: 'Standard'
  },
  showdance: {
    de: 'Gardetanz',
    en: 'Showdance'
  },
  tango: {
    de: 'Salsa & Tango',
    en: 'Salsa & Tango'
  },
  trainer: {
    de: 'Trainer',
    en: 'Trainer'
  },
  ballroom: {
    de: 'Sneaker',
    en: 'Sneaker'
  },
  wedding: {
    de: 'Hochzeit',
    en: 'Wedding'
  },
  variospin: {
    de: 'VarioSpin',
    en: 'VarioSpin'
  },
  variopro: {
    de: 'VarioPro',
    en: 'VarioPro'
  },
  bridal: {
    de: 'Brautschuhe',
    en: 'Bridal'
  },
  boys: {
    de: 'Knaben',
    en: 'Boys'
  },
  girls: {
    de: 'Mädchen',
    en: 'Girls'
  },
  comfy: {
    de: 'Bequem',
    en: 'Comfy'
  },
  festive: {
    de: 'Festlich',
    en: 'Festive'
  },
  wide: {
    de: 'Weit',
    en: 'Wide'
  },
  black: {
    de: 'Schwarz',
    en: 'Black'
  },
  silver: {
    de: 'Silber',
    en: 'Silver'
  },
  casual: {
    de: 'Casual',
    en: 'Casual'
  },
  accessories: {
    de: 'Zubehör',
    en: 'Accessories'
  },
  misc: {
    de: 'Sonstiges',
    en: 'Other'
  },
  returnPolicy: {
    de: '60 Tage Rückgaberecht',
    en: '60-Day Return Policy'
  },
  madeInGermany: {
    de: 'Versand binnen 24h',
    en: 'Shipping within 24h'
  },
  freeReturns: {
    de: 'Kostenloser Versand',
    en: 'Free shipping (DE)'
  },
  favs: {
    de: 'Favoriten',
    en: 'Favorites'
  },
  merchantLogin: {
    de: 'Händler-Login',
    en: 'Merchant Login'
  },
  storeLocator: {
    de: 'Händlersuche',
    en: 'Store Locator'
  },
  onlineShops: {
    de: 'Online-Shops',
    en: 'Online-Shops'
  },
  catalog: {
    de: 'Katalog',
    en: 'Catalog'
  },
  news: {
    de: 'Diamant News',
    en: 'Diamant News'
  },
  new: {
    de: 'Neu',
    en: 'New'
  },
  sale: {
    de: 'Sale',
    en: 'Sale'
  },
  impersonationInfo: {
    de: 'Sie impersonaten den Nutzeraccount:',
    en: 'You are impersonating user:'
  },
  stopImpersonating: {
    de: 'Beenden',
    en: 'Stop impersonating'
  }
};

export const routes = {
  home: {
    de: '',
    en: 'en'
  },
  new: {
    de: 'neu',
    en: 'new'
  },
  sale: {
    de: 'sale',
    en: 'sale'
  },
  cart: {
    de: 'warenkorb',
    en: 'en/cart'
  },
  favs: {
    de: 'favoriten',
    en: 'favorites'
  },
  ladies: {
    root: {
      de: 'damen',
      en: 'ladies'
    },
    latin: {
      de: 'damen/latein',
      en: 'ladies/latin'
    },
    standard: {
      de: 'damen/standard',
      en: 'ladies/standard'
    },
    showdance: {
      de: 'damen/gardetanz',
      en: 'ladies/showdance'
    },
    trainer: {
      de: 'damen/trainer',
      en: 'ladies/trainer'
    },
    wedding: {
      de: 'damen/hochzeit',
      en: 'ladies/wedding'
    },
    variospin: {
      de: 'damen/variospin',
      en: 'ladies/variospin'
    },
    comfy: {
      de: 'damen/bequeme-schuhe',
      en: 'ladies/comfy-shoes'
    },
    festive: {
      de: 'damen/festlich',
      en: 'ladies/festive'
    },
    black: {
      de: 'damen/schwarz',
      en: 'ladies/black'
    },
    silver: {
      de: 'damen/silber',
      en: 'ladies/silver'
    },
    new: {
      de: 'damen/neu',
      en: 'ladies/new'
    },
    sale: {
      de: 'damen/sale',
      en: 'ladies/sale'
    }
  },
  mens: {
    root: {
      de: 'herren',
      en: 'mens'
    },
    latin: {
      de: 'herren/latein',
      en: 'mens/latin'
    },
    standard: {
      de: 'herren/standard',
      en: 'mens/standard'
    },
    tango: {
      de: 'herren/salsa-tango',
      en: 'mens/salsa-tango'
    },
    ballroom: {
      de: 'herren/sneaker',
      en: 'mens/sneaker'
    },
    wedding: {
      de: 'herren/hochzeit',
      en: 'mens/wedding'
    },
    variospin: {
      de: 'herren/variospin',
      en: 'mens/variospin'
    },
    casual: {
      de: 'herren/casual',
      en: 'mens/casual'
    },
    comfy: {
      de: 'herren/bequem',
      en: 'mens/comfy'
    },
    wide: {
      de: 'herren/weit',
      en: 'mens/wide'
    },
    new: {
      de: 'herren/neu',
      en: 'mens/new'
    },
    sale: {
      de: 'herren/sale',
      en: 'mens/sale'
    }
  },
  children: {
    root: {
      de: 'kinder',
      en: 'children'
    },
    boys: {
      de: 'kinder/knaben',
      en: 'children/boys'
    },
    girls: {
      de: 'kinder/mädchen',
      en: 'children/girls'
    }
  },
  misc: {
    variopro: {
      de: 'variopro',
      en: 'variopro'
    },
    calendar: {
      de: 'kalender',
      en: 'calendar'
    },
    accessories: {
      de: 'zubehör',
      en: 'accessories'
    }
  },
  login: {
    de: 'login',
    en: 'login'
  },
  storeLocator: {
    de: 'storelocator',
    en: 'storelocator'
  },
  onlineShops: {
    de: 'online-shops',
    en: 'online-shops'
  },
  catalog: {
    de: 'tanzschuhe-katalog',
    en: 'dance-shoes-catalog'
  },
  news: {
    de: 'blog',
    en: 'blog'
  }
};
