<div class="product-typeahead">
  <ng-template #rt let-r="result" let-t="term">
    <div class="d-flex flex-column child" [ngClass]="{selected: r.articleNumber || r.searchHits}">
      <div class="d-flex flex-row align-items-center">
        <div class="image d-flex justify-content-center align-items-center" *ngIf="r.articleNumber">
          <app-icon class="favorite" [ngClass]="{'d-none': !isFavorite(r.articleNumber)}">star</app-icon>
          <advanced-image [alt]="r.articleNumber + ' | Thumbnail main'" class="img-responsive main-image" [cldImg]="images[r.image]"></advanced-image>
        </div>
        <div class="description d-flex flex-column">
          <div *ngIf="r.articleNumber" class="article-no d-flex flex-row">
            <ngb-highlight [result]="r.articleNumber" [term]="t"></ngb-highlight>
          </div>
          <div class="article-properties" *ngIf="!r.categories?.includes('Z01') && r.properties">
            <p>
              <ngb-highlight [result]="getSearchTitle(r)" [term]="t"></ngb-highlight>
            </p>
            <p>
              <ngb-highlight [result]="r.properties.heelType | l" [term]="t"></ngb-highlight>
            </p>
            <p>
              <ngb-highlight [result]="(r.properties.color | l) + ' ' + (r.properties.material | l)" [term]="t">
              </ngb-highlight>
            </p>
          </div>
        </div>
      </div>

      <div *ngIf="r.moreChars">{{text.moreCharacters | l}}</div>
      <div *ngIf="r.searchHits >= 0 && loading">{{text.loading | l}}</div>

      <div *ngIf="r.searchHits === 0 && !loading"> {{text.noHits | l}} </div>

      <div *ngIf="r.searchHits">{{text.showAll | l}}</div>
      <div class="results" *ngIf="r.searchHits">({{r.searchHits}} {{text.results | l}})</div>
    </div>

  </ng-template>


  <input class="form-control text search-default" [(ngModel)]="searchTerm" [ngbTypeahead]="search"
         [placement]="placement" [resultTemplate]="rt" (ngModelChange)="onChange($event)"
         (selectItem)="onSelect($event)" (blur)="onBlur($event)"
         (focus)="onFocus($event)" (keyup.enter)="onEnter($event)" (keydown)="typeaheadChanged($event)" type="search"
         #searchInput
         #typeaheadInstance="ngbTypeahead" [placeholder]="text.search | l" [inputFormatter]="formatter">

  <button class="input-group-addon" tabindex="-1" (click)="onIcon()" [disabled]="loading">
    <app-icon>search</app-icon>
  </button>

</div>

<ngb-progressbar *ngIf="loading" [height]="'8px'" [value]="100" [striped]="true" [animated]="true"></ngb-progressbar>

