import {Component, OnDestroy, OnInit} from '@angular/core';
import {LanguageService} from '../../language.service';

@Component({
  selector: 'app-usp-slider',
  templateUrl: './usp-slider.component.html',
  styleUrls: ['./usp-slider.component.scss']
})
export class UspSliderComponent implements OnInit, OnDestroy {

  public text = {
    returnPolicy: {
      de: '60 Tage Rückgaberecht',
      en: '60-Day Return Policy'
    },
    madeInGermany: {
      de: 'Versand binnen 24h',
      en: 'Shipping within 24h'
    },
    freeReturns: {
      de: 'Kostenloser Versand',
      en: 'Free shipping (DE)'
    },
  };

  index = 0;
  interval;

  constructor(public langS: LanguageService) {
  }

  ngOnInit() {
    this.interval = setInterval(() => {
      if (this.index < 2) {
        this.index++;
      } else {
        this.index = 0;
      }
    }, 4750);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

}
