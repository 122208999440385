import {
  AfterViewInit,
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from "@angular/core";
import { StorelocatorService } from "./storelocator.service";
import { Coordinates, Store } from "./dtos/store.dto";
import { text } from "./storelocator.i18n";
import { of } from "rxjs";
import { tap } from "rxjs/operators";
import { SeoService } from "../seo.service";
import { LanguageService } from "../language.service";
import { Product } from "../../models/product";
import { LocalStore } from "./dtos/local-store.dto";

@Component({
  selector: "app-storelocator",
  templateUrl: "./storelocator.component.html",
  styleUrls: ["./storelocator.component.scss"],
})
export class StorelocatorComponent implements OnInit, AfterViewInit {
  @ViewChild("addressInput") addressInput: ElementRef;

  stores: LocalStore[];
  coordinates: Coordinates;
  selectedStore: number;
  searched = false;
  zoom = 2;
  savedAddress = "";
  options: google.maps.MapOptions;
  text = text;
  displayAssortment = false;
  assortment: Product[];

  constructor(
    private storelocatorService: StorelocatorService,
    private _zone: NgZone,
    private seo: SeoService,
    public languageService: LanguageService
  ) {}

  ngOnInit() {
    this.seo.setTitle(text.seoTitle[this.languageService.l]);
    this.seo.setDescription(text.seoDesc[this.languageService.l]);
    of(localStorage.getItem("coordinates"))
      .pipe(
        tap((coords) => (coords ? this.checkStorage() : this.getPosition()))
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    const autocomplete = new google.maps.places.Autocomplete(
      this.addressInput.nativeElement,
      { types: ["locality", "postal_code", "administrative_area_level_3"] }
    );
    google.maps.event.addListener(autocomplete, "place_changed", () =>
      this._zone.run(() => {
        const place = autocomplete.getPlace();
        this.savedAddress = place.formatted_address ?? "";
        this.coordinates = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        localStorage.setItem("savedAddress", this.savedAddress);
        localStorage.setItem("coordinates", JSON.stringify(this.coordinates));
        this.findStores();
      })
    );
  }

  getPosition() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => this.showPosition(position),
        () => (this.options = null)
      );
    }
  }

  checkStorage() {
    this.coordinates = JSON.parse(localStorage.getItem("coordinates"));
    this.savedAddress = localStorage.getItem("savedAddress") ?? "";
    this.findStores();
  }

  switchSelection() {
    this.displayAssortment = !this.displayAssortment;
  }

  getMapsUrl(shopName: string, latitude: number, longitude: number) {
    return `https://maps.google.com/?q=${encodeURIComponent(shopName)}&near=${latitude},${longitude}`;
  }

  getAssortment(addressNumber: number) {
    this.storelocatorService
      .getAssortment(addressNumber)
      .subscribe((products) => {
        this.assortment = products;
        this.displayAssortment = true;
        this.selectedStore = addressNumber;
      });
  }

  showMap() {
    this.displayAssortment = false;
    this.assortment = null;
  }

  showPosition(position: GeolocationPosition) {
    this.coordinates = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    localStorage.setItem("coordinates", JSON.stringify(this.coordinates));
    this.findStores();
  }

  findStores() {
    this.storelocatorService.findStores(this.coordinates).subscribe((res) => {
      this.stores = res;
      this.zoom = 9;
      this.searched = true;
      this.options = {
        zoom: this.zoom,
        center: this.coordinates,
      };
    });
  }

  selectMarker(store: Store) {
    this.selectedStore = store.addressNumber;
    this.scrollTo(store);
  }

  scrollTo(store: Store) {
    const element = document.getElementById(store.addressNumber.toString());

    element.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  }
}
