export const text = {
  addCollection: {
    de: 'Neue Merkliste erstellen',
    en: 'Create new collection'
  },
  uniqueCollectionNames: {
    de: 'Bitte wählen Sie einen eindeutigen Namen.',
    en: 'Please pick a unique name.'
  },
  articleAdded: {
    de: 'Artikel zum Warenkorb hinzugefügt.',
    en: 'Added product to cart.'
  },
  toCheckout: {
    de: 'Zur Kasse',
    en: 'Checkout'
  },
  articleUpdated: {
    de: 'Artikel aktualisiert',
    en: 'Products updated'
  }
};
