export const text = {
  locationError: {
    de: "Sie müssen die Standorterkennung zulassen, um die Karte anzuzeigen.",
    en: "You need to allow location detection to view the map.",
  },
  enterAddress: {
    de: "Bitte geben Sie eine gültige Adresse ein",
    en: "Please enter a valid address",
  },
  seoTitle: {
    de: 'Diamant Tanzschuhe | Ladengeschäft in ihrer Nähe finden',
    en: 'Diamant Dance Shoes | Find a store near you',
  },
  seoDesc: {
    de: 'Jetzt Händler in ihrer Nähe finden | Frankfurt, Berlin, Köln, München, Hamburg.... | Latein, Standard, Salsa & Tango | Diamant Tanzschuhe®',
    en: 'Find your nearest retailer now | Frankfurt, Berlin, Cologne, Munich, Hamburg.... | Latin, Standard, Salsa & Tango | Diamant Tanzschuhe®'
  },
  website: {
    de: 'Zur Website',
    en: 'Visit website'
  },
  showAssortment: {
    de: 'Sortiment anzeigen',
    en: 'Show assortment'
  },
  showMap: {
    de: 'Karte anzeigen',
    en: 'Show map'
  },
  storelist: {
    de: 'Storeliste',
    en: 'Storelist'
  },
  assortment: {
    de: 'Sortiment',
    en: 'Assortment'
  }
};
