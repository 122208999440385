import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from "@angular/core";

// Inspiration: https://gist.github.com/darrenmothersele/7afda13d858a156ce571510dd78b7624.
@Component({
  selector: "app-file-import",
  templateUrl: "./file-import.component.html",
  styleUrls: ["./file-import.component.scss"],
})
export class FileImportComponent {
  text = {
    de: "Datei per Klick oder Drag & Drop importieren",
    en: "Import via click or drag & drop",
  };

  @Input() acceptedFileFormats = "";

  @Output() fileDrop = new EventEmitter<File>();
  @HostBinding("class.drop-zone-active") active = false;

  constructor() {}

  onInputChange(event) {
    this.fileDrop.emit(event.target.files.item(0));
  }

  @HostListener("drop", ["$event"])
  onDrop(event: DragEvent) {
    event.preventDefault();
    this.active = false;

    const { dataTransfer } = event;
    if (dataTransfer.items) {
      // Just use first file found. Multi-file support could easily be added here.
      // Reject item if it's not a file.
      if (dataTransfer.items[0].kind === "file") {
        this.fileDrop.emit(dataTransfer.items[0].getAsFile());
      }
      dataTransfer.items.clear();
    } else {
      this.fileDrop.emit(dataTransfer.files[0]);
      dataTransfer.clearData();
    }
  }

  @HostListener("dragover", ["$event"])
  onDragOver(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.active = true;
  }

  @HostListener("dragleave", ["$event"])
  onDragLeave(event: DragEvent) {
    this.active = false;
  }

  // Disable browser's default drag n drop behaviour
  // (don't load file in browser when user missed the drop area).
  @HostListener("body:dragover", ["$event"])
  onBodyDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener("body:drop", ["$event"])
  onBodyDrop(event: DragEvent) {
    event.preventDefault();
  }
}
