import {Component, Input} from '@angular/core';
import {SizeService} from '../size/size.service';
import {text} from './../size/size.i18n';

@Component({
  selector: 'app-size-format-dropdown',
  templateUrl: './size-format-dropdown.component.html',
  styleUrls: ['./size-format-dropdown.component.scss']
})
export class SizeFormatDropdownComponent {

  text = text;

  @Input() placement = 'bottom-left';

  constructor(public ss: SizeService) {
  }

  onSelect(format, event) {
    event.preventDefault();
    this.ss.format = format;
  }
}
