import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { CloudinaryImage } from "@cloudinary/url-gen";
import { LanguageService } from "../../language.service";
import { AnalyticsService } from "../../analytics.service";
import { FavoritesService } from "../../search/favorites/favorites.service";
import { ToastService } from "../toast/toast.service";
import { CartService } from "../../cart/cart.service";
import { text } from "./item.i18n";
import { text as sizeText } from "../size/size.i18n";
import { Product } from "../../../models/product";
import { LoginService } from "../../user/login/login.service";
import { CloudinaryService } from "../../cloudinary.service";

@Component({
  selector: "app-item",
  templateUrl: "./item.component.html",
  styleUrls: ["./item.component.scss"],
})
export class ItemComponent implements OnInit {
  clImage: CloudinaryImage;
  previewImage: CloudinaryImage;
  showPreview = false;
  text = text;
  sizeText = sizeText;

  private _content: Product;
  productStatus: string;
  @Input() set content(data) {
    this._content = new Product(data);
  }

  get content(): Product {
    return this._content;
  }

  @Input() isInteractive = true;
  @Input() listView = false;

  public enteredViewport = false;
  private params: any;
  private imageNotFound = false;

  get itemImage() {
    return this.showPreview ? this.previewImage : this.clImage;
  }

  get displayRetailPrice() {
    return this.content?.retailPrice && !this.loginService.isLoggedIn;
  }

  constructor(
    private route: ActivatedRoute,
    public ls: LanguageService,
    public as: AnalyticsService,
    public favs: FavoritesService,
    public ts: ToastService,
    public cs: CartService,
    public loginService: LoginService,
    private cloudinaryService: CloudinaryService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(
      (params: Params) => (this.params = params["term"])
    );
    this.checkProductStatus();
  }

  isFavorite(): boolean {
    return this.favs.isFavorite(this.content);
  }

  toggleFav(event: MouseEvent) {
    event.stopPropagation();
    if (this.isFavorite()) {
      this.favs.removeFromFavs(this.content);
    } else {
      this.favs.addToFavs(this.content);
    }
  }

  loadImage() {
    this.clImage = this.cloudinaryService.buildImage(
      this.content.image + "/main",
      { height: 315, width: 400 }
    );
    this.enteredViewport = true;
  }

  showPreviewImage() {
    if (this.previewImage) {
      this.showPreview = true;
    } else if (this.previewImage === undefined) {
      this.cloudinaryService
        .createPreview(this.content.image)
        .subscribe((image) => {
          if (image) {
            this.previewImage = image;
            this.showPreview = true;
          } else {
            this.previewImage = null;
          }
        });
    }
  }

  resetToOriginal() {
    if (this.previewImage) {
      this.showPreview = false;
    }
  }

  click() {
    this.as.clickOnItem(this.content, this.loginService.isLoggedIn);
  }

  public getLink() {
    let descPart = "";
    try {
      descPart += this.content.title[this.ls.l].replace(/\s+/g, "-");
      descPart = descPart.replace(/\//g, "");
      descPart = descPart.replace(/\(/g, "");
      descPart = descPart.replace(/\)/g, "");
      // descPart = descPart.replace('.', '');
      descPart = descPart.toLowerCase();
      descPart = "/" + descPart;
    } catch (e) {}
    return `${this.getPrefix(
      this.content.categories
    )}${this.getProductNumber()}${descPart}`;
  }

  public getProductNumber() {
    return this.content.articleNumber;
  }

  public isAccessory() {
    return this.content.categories && this.content.categories[0] === "Z01";
  }

  public get image() {
    if (!this.imageNotFound) {
      return this.getProductNumber();
    }
    return undefined;
  }

  public get isStock(): boolean {
    return this.content.isStock;
  }

  get nonStockText() {
    return this.loginService.isLoggedIn
      ? sizeText.nonStockShoe
      : sizeText.nonStockShoeB2C;
  }

  get prop() {
    return this.content.properties?.heelType[this.ls.l].split(" ").length <= 3 ? this.content.properties?.heelType : this.content.properties?.width;
  }

  public get isExternalWarehouse(): boolean {
    if (!this.isInteractive) {
      return false;
    }
    if (this.loginService.isLoggedIn && this.content.groups) {
      return this.content.groups.includes("ART_GRU");
    } else {
      return false;
    }
  }

  // TODO: Use categories service
  private getPrefix(categories) {
    const de = this.ls.l !== "en";
    let superCategory = "/";
    let subCategory = "/";

    if (categories) {
      // Children
      if (categories.includes("K01")) {
        return de ? "/kinder/" : "/en/children/";
      }

      // Determine Super
      if (categories.includes("H01")) {
        superCategory = de ? "/herren" : "/en/mens";
      }
      if (categories.includes("D01")) {
        superCategory = de ? "/damen" : "/en/ladies";
      }

      // Determine Subcategory
      if (
        categories.includes("H04_Tango")
      ) {
        subCategory = "/tango";
      }
      if (categories.includes("D04_Garde")) {
        subCategory = de ? '/gardetanz' : '/showdance';
      }
      if (categories.includes("D06_Trai")) {
        subCategory = "/trainer";
      }
      if (
        categories.includes("D02_Latein") ||
        categories.includes("H02_Latein")
      ) {
        subCategory = de ? "/latein" : "/latin";
      }
      if (
        categories.includes("D03_Standa") ||
        categories.includes("H03_Standa")
      ) {
        subCategory = "/standard";
      }
      if (categories.includes("H06_Ballro")) {
        subCategory = "/sneaker";
      }
    }

    // Check if all went fine
    if (superCategory === "/" || subCategory === "/") {
      return de ? "/artikel/" : "/en/article/";
    }

    return superCategory + subCategory + "/";
  }

  checkProductStatus() {
    if (this._content && this._content.categories) {
      if (
        this._content.categories.includes("B02") &&
        this._content.categories.includes("B01")
      ) {
        this.productStatus = "both";
      } else if (this._content.categories.includes("B02")) {
        this.productStatus = "sale";
      } else if (this._content.categories.includes("B01")) {
        this.productStatus = "neu";
      }
    }
  }
}
