<div ngbDropdown autoClose="outside" class="dropdown">
  <button class="btn" ngbDropdownToggle>
    {{ sortLabel }}
  </button>
  <div ngbDropdownMenu class="dropdown-menu">
    <button
      class="dropdown-item"
      *ngFor="let item of options"
      (click)="changeSelection(item)"
      [class.active]="isActive(item)"
    >
      <input type="checkbox" *ngIf="multi" [ngModel]="isActive(item)" />
      <span>{{ item[languageService.l] }}</span>
    </button>
  </div>
</div>
