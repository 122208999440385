import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {ButtonComponent} from './button/button.component';
import {DropdownComponent} from './dropdown/dropdown.component';
import {FooterComponent} from './footer/footer.component';
import {IconComponent} from './icon/icon.component';
import {LanguageSwitcherComponent} from './language-switcher/language-switcher.component';
import {NavbarComponent} from './navbar/navbar.component';
import {ProductBarComponent} from './product-bar/product-bar.component';
import {ProductShowcaseComponent} from './product-showcase/product-showcase.component';
import {ToastComponent} from './toast/toast.component';
import {OverlayComponent} from './navbar/overlay/overlay.component';
import {SearchbarComponent} from './navbar/searchbar/searchbar.component';
import {RouterModule} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {LanguagePipe} from '../language.pipe';
import {ItemComponent} from './item/item.component';
import {CategoriesComponent} from './categories/categories.component';
import {LanguagePickerComponent} from './navbar/language-picker/language-picker.component';
import {VisibleDirective} from '../visible.directive';
import {LoadingBarComponent} from './navbar/loading-bar/loading-bar.component';
import {ZoomableDirective} from './zoomable/zoomable.directive';
import {ProductTypeaheadComponent} from './product-typeahead/product-typeahead.component';
import {SizeFormatDropdownComponent} from './size-format-dropdown/size-format-dropdown.component';
import {OrderButtonComponent} from './order-button/order-button.component';
import {SizeSelectComponent} from './size/size-select/size-select.component';
import {OrderComponent} from './order/order.component';
import {CollectionTabsComponent} from './collection-tabs/collection-tabs.component';
import {HighlightWidthPropDirective} from './highlight-width-prop/highlight-width-prop.directive';
import {FileImportComponent} from './file-import/file-import.component';
import {SizeDisplayComponent} from './size/size-display/size-display.component';
import {AmountPipe} from '../amount.pipe';
import {RouteLanguagePipe} from '../route-language.pipe';
import { CloudinaryModule } from '@cloudinary/ng';
import { ImageCarouselComponent } from './image-carousel/image-carousel.component';
import { JsonLdComponent } from './json-ld/json-ld.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    NgbModule,
    CloudinaryModule,
  ],
  declarations: [
    ButtonComponent,
    DropdownComponent,
    FooterComponent,
    IconComponent,
    LanguageSwitcherComponent,
    NavbarComponent,
    ProductBarComponent,
    ProductShowcaseComponent,
    ToastComponent,
    OverlayComponent,
    SearchbarComponent,
    LanguagePipe,
    RouteLanguagePipe,
    AmountPipe,
    LanguagePickerComponent,
    ItemComponent,
    CategoriesComponent,
    VisibleDirective,
    LoadingBarComponent,
    ZoomableDirective,
    SizeSelectComponent,
    ProductTypeaheadComponent,
    SizeFormatDropdownComponent,
    OrderButtonComponent,
    OrderComponent,
    CollectionTabsComponent,
    FileImportComponent,
    SizeDisplayComponent,
    HighlightWidthPropDirective,
    ImageCarouselComponent,
    JsonLdComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    NgbModule,
    CloudinaryModule,
    ButtonComponent,
    DropdownComponent,
    FooterComponent,
    IconComponent,
    LanguageSwitcherComponent,
    LanguagePickerComponent,
    NavbarComponent,
    ProductBarComponent,
    ProductShowcaseComponent,
    ToastComponent,
    OverlayComponent,
    SearchbarComponent,
    LanguagePipe,
    RouteLanguagePipe,
    AmountPipe,
    ItemComponent,
    CategoriesComponent,
    VisibleDirective,
    ZoomableDirective,
    SizeSelectComponent,
    SizeDisplayComponent,
    OrderComponent,
    ProductTypeaheadComponent,
    OrderButtonComponent,
    CollectionTabsComponent,
    HighlightWidthPropDirective,
    LoadingBarComponent,
    FileImportComponent,
    ImageCarouselComponent,
    JsonLdComponent,
  ],
})
export class SharedModule {
}
