import { LanguageService } from "./../../../language.service";
import { SizeService } from "./../size.service";
import { animations, text } from "./../size.i18n";
import { Component, Input } from "@angular/core";
import { Product } from "../../../../models/product";

@Component({
  selector: "app-size-display",
  templateUrl: "./size-display.component.html",
  styleUrls: ["./size-display.component.scss"],
  animations: animations,
})
export class SizeDisplayComponent {
  public text = text;
  private _product: Product;

  @Input()
  set product(product: Product) {
    if (product && !product.size && !product.amount) {
      product.size = [];
    }
    this._product = product;
  }

  get product(): Product {
    return this._product;
  }

  constructor(public ss: SizeService, public ls: LanguageService) {}
}
