import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LoginService } from './user/login/login.service';
import { LocalStorageService } from './local-storage.service';
import { environment } from '../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(private ls: LoginService, private storage: LocalStorageService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.startsWith(environment.directus_url)) {
      return next.handle(request);
    }

    const token = this.storage.retrieve('auth');
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    if (!this.ls.isLoggedIn || this.ls.loginPending) {
      request = request.clone({
        setParams: {
          client: !this.ls.loginPending ? 'b2c' : this.randomString,
        },
      });
    }

    return next
      .handle(request)
      .pipe(catchError((err) => this.handleResError(err)));
  }

  // This is used to generate a random client name to force cache bypassing while user login is still pending
  get randomString() {
    let result = '';
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charLen = chars.length;
    for (let i = 0; i < 4; i++) {
      result += chars.charAt(Math.floor(Math.random() * charLen));
    }
    return result;
  }

  private handleResError(err: unknown) {
    if (err instanceof HttpErrorResponse && err.status === 401) {
      this.ls.logout();
    }
    return throwError(err);
  }
}
