import {Injectable} from '@angular/core';

/**
 * Size service to translate between different sizing systems
 */
@Injectable({
  providedIn: 'root'
})
export class SizeService {

  // options: EU, UK, US
  private _format: string = 'UK';

  public EU = {
    'H ': ['37½', '38', '38½', '39½', '40', '40½', '41', '42', '42½', '43', '44', '44½', '45', '45½', '46½', '47', '47½', '48'],
    'H1': ['36', '37', '37½', '38', '38½', '39½', '40', '40½', '41', '42', '42½', '43', '44', '44½', '45', '45½', '46½', '47', '47½', '48'],
    'D ': ['33½', '34½', '35', '35½', '36', '37', '37½', '38', '38½', '39½', '40', '40½', '41', '42', '42½', '43', '44'],
    'D5': ['35', '35½', '36', '37', '37½', '38', '38½', '39½', '40', '40½', '41', '42', '42½', '43', '44', '44½', '45'],
    'K ': ['', '', '', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39'],
    'M ': ['', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39'],
    'SC': ['1', '2', '3'],
    'S1': ['35', '36', '37', '37½', '38', '38½', '39½', '40', '40½', '41', '42', '42½', '43', '44', '44½', '45', '45½', '46½', '47', '47½']
  };
  public UK = {
    'H ': ['4.5', '5', '5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9', '9.5', '10', '10.5', '11', '11.5', '12', '12.5', '13'],
    'H1': ['3.5', '4', '4.5', '5', '5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9', '9.5', '10', '10.5', '11', '11.5', '12', '12.5', '13'],
    'D ': ['1.5', '2', '2.5', '3', '3.5', '4', '4.5', '5', '5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9', '9.5'],
    'D5': ['2.5', '3', '3.5', '4', '4.5', '5', '5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9', '9.5', '10', '10.5'],
    'K ': this.EU['K '],
    'M ': this.EU['M '],
    'SC': ['1', '2', '3'],
    'S1': ['3', '3.5', '4', '4.5', '5', '5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9', '9.5', '10', '10.5', '11', '11.5', '12', '12.5']
  };
  public US = {
    'H ': ['5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9', '9.5', '10', '10.5', '11', '11.5', '12', '12.5', '13', '13.5', '14'],
    'H1': ['4.5', '5', '5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9', '9.5', '10', '10.5', '11', '11.5', '12', '12.5', '13', '13.5', '14'],
    'D ': ['4', '4.5', '5', '5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9', '9.5', '10', '10.5', '11', '11.5', '12'],
    'D5': ['5', '5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9', '9.5', '10', '10.5', '11', '11.5', '12', '12.5', '13'],
    'K ': this.EU['K '],
    'M ': this.EU['M '],
    'SC': ['1', '2', '3'],
    'S1': this.EU['S1']
  };

  constructor() {
  }

  get format() {
    return this._format;
  }

  set format(format: string) {
    this._format = format;
  }

}
