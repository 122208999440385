import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
// import * as Sentry from "@sentry/angular";
// import { BrowserTracing } from "@sentry/tracing";

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

// Sentry.init({
//   dsn: "https://0a175213c19846ac88cbbe3f7adf057e@o1159139.ingest.sentry.io/4504101145083904",
//   integrations: [
//     new BrowserTracing({
//       tracingOrigins: ["localhost", "https://www.diamant.net"],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],
//   tracesSampleRate: 1.0,
// });

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
