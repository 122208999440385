import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, Observable, of, tap } from "rxjs";
import { Coordinates, Store } from "./dtos/store.dto";
import { Product } from "../../models/product";
import { LocalStore } from "./dtos/local-store.dto";

@Injectable({
  providedIn: "root",
})
export class StorelocatorService {
  productAssortment: Record<number, Product[]> = {};

  constructor(private http: HttpClient) {}

  findStores(coordinates: Coordinates): Observable<LocalStore[]> {
    const options = {
      params: {
        ...coordinates,
      },
    };

    return this.http.get<LocalStore[]>(`/api/storelocator/local-stores`, options);
  }

  getAssortment(addressNumber: number): Observable<Product[]> {
    if (this.productAssortment[addressNumber]) {
      return of(this.productAssortment[addressNumber]);
    }

    const options = {
      params: {
        shopId: addressNumber,
      },
    };

    return this.http
      .get<Product[]>("/api/storelocator/local-stores/products", options)
      .pipe(
        catchError(() => of([])),
        tap((products) => (this.productAssortment[addressNumber] = products))
      );
  }

  getCountryList(id: string) {
    const options = {
      params: {
        articleNumber: id,
      },
    };

    return this.http.get<string[]>(
      "/api/storelocator/online-stores/countries",
      options
    );
  }
}
