import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { routes, text } from "./product-typeahead.i18n";
import { CategoriesService } from "../categories/categories.service";
import { SearchService } from "../../search/search.service";
import { NavbarService } from "../navbar/navbar.service";
import { LanguageService } from "../../language.service";
import { FavoritesService } from "../../search/favorites/favorites.service";
import {
  distinctUntilChanged,
  map,
  switchAll,
  switchMap,
  tap,
} from "rxjs/operators";
import { Observable, of } from "rxjs";
import { Cloudinary } from "@cloudinary/url-gen";
import { cloudinaryConfig } from "../../../cloudinary-config";
import { format } from "@cloudinary/url-gen/actions/delivery";
import { auto } from "@cloudinary/url-gen/qualifiers/format";
import { pad } from "@cloudinary/url-gen/actions/resize";
import { CloudinaryImages } from "../../cloudinary.service";

const cl = new Cloudinary(cloudinaryConfig);

@Component({
  selector: "app-product-typeahead",
  templateUrl: "./product-typeahead.component.html",
  styleUrls: ["./product-typeahead.component.scss"],
})
export class ProductTypeaheadComponent implements OnInit {
  images: CloudinaryImages = {};
  text = text;

  loading = false;

  routes = routes;

  @Input() preselect = true;

  @Input() placement = "bottom-left";

  @Output() selectItem = new EventEmitter();

  @Output() inputBlur = new EventEmitter();

  @Output() inputFocus = new EventEmitter();

  @Output() iconClick = new EventEmitter();

  @Output() enter = new EventEmitter();

  searchTerm = "";
  activeTerm = "";

  @ViewChild("searchInput", { static: true }) searchInput: ElementRef;

  @ViewChild("typeaheadInstance", { static: true })
  private typeaheadInstance: NgbTypeahead;

  constructor(
    public catS: CategoriesService,
    public ss: SearchService,
    public ns: NavbarService,
    public ls: LanguageService,
    public favs: FavoritesService
  ) {}

  private static scrollIntoViewIfNeededPolyfill(elem: HTMLElement) {
    const parent = elem.parentElement;

    const overTop = elem.offsetTop < parent.scrollTop;
    const overBottom =
      elem.offsetTop + elem.offsetHeight >
      parent.offsetHeight + parent.scrollTop;

    if (overTop || overBottom) {
      parent.scrollTop = elem.offsetTop - elem.offsetHeight / 2;
    }
  }

  ngOnInit() {
    if (this.ns.focusSearch) {
      this.searchInput.nativeElement.focus();
    }
  }

  onSelect(e) {
    if (!e.item.searchHits) {
      this.selectItem.emit(e);
    } else {
      this.enter.emit(this.activeTerm);
    }
    this.loading = false;
    setTimeout(() => {
      this.searchTerm = "";
    });
  }

  onFocus(e) {
    this.inputFocus.emit(e);
  }

  onBlur(e) {
    this.loading = false;
    this.inputBlur.emit(e);
  }

  onIcon() {
    this.iconClick.emit(this.searchTerm);
  }

  onChange(e) {
    if (e !== "") {
      this.loading = true;
    }
  }

  onEnter(e) {
    if (this.loading) {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
    } else {
      // this.enter.emit(this.activeTerm);
    }
  }

  public getSearchTitle(product: any): string {
    const categories = this.catS.getCategoryHierarchy(product.categories);
    return `${product.name} ${categories[0]} ${categories[1]}`;
  }

  formatter = (x) => x.articleNumber;

  public search = (text$: Observable<any>) => {
    return text$.pipe(
      distinctUntilChanged(),
      map((term) => {
        this.activeTerm = term;
        this.loading = term.length > 2;
        return { moreChars: term.length <= 2, term };
      }),
      switchMap((x) => {
        if (x.moreChars) {
          return of(of(x));
        } else {
          return of(
            of(x),
            this.ss.search(x.term, 4, 0).pipe(
              tap((y) => {
                y.result.forEach((x) => {
                  if (x.image) {
                    this.images[x.image] = cl
                      .image(x.image + "/main")
                      .resize(pad().height(80).width(80))
                      .delivery(format(auto()));
                  }
                });
              }),
              map((y) => {
                y.term = x.term;
                return y;
              })
            )
          );
        }
      }),
      switchAll(),
      map((o) => {
        if (o.result) {
          this.loading = false;
        }

        if (o.moreChars) {
          return o;
        }

        if (!o.count && this.searchTerm.length > 2) {
          return { searchHits: 0, term: o.term };
        }

        if (o.count > 4) {
          const s = o.result;
          s.push({ searchHits: o.count });
          s.term = o.term;
          return s;
        }
        o.result.term = o.term;
        return o.result;
      }),
      map((x) => {
        const result = [];
        const url = { term: x.term };
        url[this.ls.l] =
          `/${this.ls.rl}${this.routes.search[this.ls.l]}/` + x.term;
        if (!this.preselect) {
          result.push({ url });
        }
        return result.concat(x);
      })
    );
  };

  isFavorite(articleNumber: string) {
    return this.favs.productIDs.includes(articleNumber);
  }

  typeaheadChanged($event: KeyboardEvent) {
    if (this.typeaheadInstance.isPopupOpen()) {
      setTimeout(() => {
        const popup = document.getElementById(this.typeaheadInstance.popupId);
        if (popup) {
          const activeElements = popup.getElementsByClassName("active");
          if (activeElements.length === 1) {
            const elem = activeElements[0] as any;
            if (typeof elem.scrollIntoViewIfNeeded === "function") {
              elem.scrollIntoViewIfNeeded();
            } else {
              ProductTypeaheadComponent.scrollIntoViewIfNeededPolyfill(
                elem as HTMLElement
              );
            }
          }
        }
      });
    }
  }
}
