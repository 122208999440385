import { Component } from "@angular/core";
import { merge, of } from "rxjs";
import { map } from "rxjs/operators";
import { CartService } from "../../cart/cart.service";
import { LoginService } from "../../user/login/login.service";
import { OverlayService } from "./overlay/overlay.service";
import { LanguageService } from "../../language.service";
import { routes, text } from "./navbar.i18n";
import { NavbarService } from "./navbar.service";
import { CategoriesService } from "../categories/categories.service";
import { ImpersonationService } from "../../user/impersonation.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent {
  public routes = routes;
  public text = text;
  user: any;

  constructor(
    public catS: CategoriesService,
    public cs: CartService,
    public ls: LoginService,
    public os: OverlayService,
    public langS: LanguageService,
    public ns: NavbarService,
    private impersonationService: ImpersonationService,
  ) {}

  getAccountRouting() {
    return merge(
      of("login"),
      this.ls.getUser().pipe(
        map((user) => {
          this.user = user;
          return this.langS.rl + (user !== undefined ? "dashboard" : "login");
        })
      )
    );
  }

  public isIE() {
    const userAgent = navigator.userAgent;
    return (
      userAgent.indexOf("MSIE ") > -1 || userAgent.indexOf("Trident/") > -1
    );
  }

  removeImpersonation() {
    this.impersonationService.removeImpersonation().subscribe();
  }
}
