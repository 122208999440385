import { Injectable } from "@angular/core";
import { io, Socket } from "socket.io-client";
import { LocalStorageService } from "./local-storage.service";
import { LoginService } from "./user/login/login.service";

@Injectable({
  providedIn: "root",
})
export class WebsocketService {
  socket: Socket;

  constructor(
    private loginService: LoginService,
    private storage: LocalStorageService
  ) {}

  connect() {
    if (!this.socket) {
      this.socket = io({
        extraHeaders: {
          Authorization: `Bearer ${this.storage.retrieve("auth")}`,
        },
      });

      this.socket.on("refresh", () => {
        this.loginService.remoteAccountInfo();
      });
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }
}
