import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {Product} from '../../../../models/product';
import {SizeService} from '../size.service';
import {CartService} from '../../../cart/cart.service';
import {LanguageService} from '../../../language.service';
import {animations, text} from '../size.i18n';
import {AmountPipe} from '../../../amount.pipe';
import {ToastService} from '../../toast/toast.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-size-select',
  templateUrl: './size-select.component.html',
  styleUrls: ['./size-select.component.scss'],
  providers: [AmountPipe],
  animations: animations
})
export class SizeSelectComponent implements OnInit, OnDestroy {

  public timeout: any;
  public text = text;
  public deliveryDate: Date | null;
  @Input() collectionName = null;
  showCount = false;
  @Input() isCart = false;
  @Input() isProductDetailPage = false;
  @Output()
  sizeChanged = new EventEmitter<{ product: Product, i: number }>();
  @Output()
  submitted = new EventEmitter();
  @ViewChildren('input') inputs: QueryList<ElementRef>;
  @ViewChild('accessoryInput', {static: true}) accessoryInput: ElementRef;
  private deliverySubscription: Subscription;

  constructor(public ss: SizeService,
              public cs: CartService,
              public ls: LanguageService,
              public ap: AmountPipe,
              private ts: ToastService,
  ) {
  }

  private _product: Product;
  public dateExtendedWarehouse;
  get product(): Product {
    return this._product;
  }

  @Input()
  set product(product: Product) {
    if (product && !product.size && !product.amount) {
      product.size = [];
    }
    this._product = product;
  }

  get collectionProducts() {
    return this.cs.getProducts(this.collectionName);
  }

  // Return lengtho of product's size array if yet initialized.
  get numberOfSizes(): number {
    if (this.ss[this.ss.format][this.product.sizeType]) {
      return this.ss[this.ss.format][this.product.sizeType].length;
    }
    return -1;
  }

  ngOnInit() {
    this.deliverySubscription = this.cs.getDeliveryDate().subscribe(d => {
      this.deliveryDate = d;
      this.extWarehouse(d);
    });
  }

  ngOnDestroy() {
    this.deliverySubscription.unsubscribe();
  }

  onSizeChanged(product: Product) {
    if (product.hasSizes) {
      if (this.isCart) {
        this.cs.changeSizeAmount(product, product.size, this.collectionProducts);
      }
    } else {
      this.onAmountChange(product, product.amount);
    }
  }

  extWarehouse(date: any) {
    this.dateExtendedWarehouse = new Date(date);
    this.dateExtendedWarehouse.setDate(this.dateExtendedWarehouse.getDate() - 10);
    try {
      this.dateExtendedWarehouse = this.dateExtendedWarehouse.toISOString();
    } catch (e) {
      console.error(`Could not transform ${this.dateExtendedWarehouse} (input ${date}) to ISO string.`)
    }
  }

  onAmountChange(product, amount) {
    if (this.isCart) {
      this.cs.changeAmount(product, amount, this.collectionProducts);
    }
  }

  onSubmit() {
    if (this.product.isBuyable) {
      this.submitted.emit(this.product);
    }
  }

  focus() {
    if (this.product.sizes) {
      let i;
      this.inputs.forEach((item, index) => {
        if (!item.nativeElement.disabled) {
          if (!(index > i)) {
            item.nativeElement.focus();
          }
          i = index;
        }
      });
    } else {
      this.accessoryInput.nativeElement.focus();
    }
  }

  getProductSize(product: Product, collection: string): number[] {
    const products = this.cs.getProducts(collection);

    const index = products.findIndex((p) => p.articleNumber === product.articleNumber);

    if (index === -1) {
      return [];
    }

    return products[index].size;
  }

  getProductAmount(product: Product, collection: string): number {
    const products = this.cs.getProducts(collection);

    const index = products.findIndex((p) => p.articleNumber === product.articleNumber);

    if (index === -1) {
      return 0;
    }

    return products[index].amount;
  }

  sanitizeInput(input) {
    // Doesn't work great for #accessoryInput as it is of type number, so that strings
    // are not passed as input here. However it still prevents decimal numbers.
    return this.ap.transform(input) as number;
  }

  forceMinOrderQuantity() {
    if (!this.product.isBuyable) {
      this.product.amount = this.product.minOrderQuantity;
    }
  }

  onSpinnerChange(amount: number, index: number, product: Product) {

    if (product.size[index] === null || product.size[index] === undefined) {
      product.size[index] = 0;
    }
    product.size[index] = product.size[index] + amount;

    if (product.size[index] < 0) {
      product.size[index] = 0;
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.onSizeChanged(product);
    }, 2000);
  }

}
