import {Subscription} from "rxjs";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output, ViewChild,
} from "@angular/core";
import {NgbNav, NgbNavChangeEvent} from "@ng-bootstrap/ng-bootstrap";
import {LanguageService} from "../../language.service";
import {ToastService} from "../toast/toast.service";
import {CartService} from "../../cart/cart.service";
import {Product} from "../../../models/product";
import {LoginService} from "../../user/login/login.service";

@Component({
  selector: "app-collection-tabs",
  templateUrl: "./collection-tabs.component.html",
  styleUrls: ["./collection-tabs.component.scss"],
})
export class CollectionTabsComponent implements OnInit, OnDestroy {
  uploading = false;
  uploadFailed = false;

  collectionFile: File;

  text = {
    addNewCollection: {
      de: "Neue Merkliste erstellen",
      en: "Create new collection",
    },
    addCollection: {
      de: "Merkliste erstellen",
      en: "Create collection",
    },
    uniqueCollectionNames: {
      de: "Bitte wählen Sie einen eindeutigen Namen.",
      en: "Please pick a unique name.",
    },
    cart: {
      de: "Warenkorb",
      en: "Cart",
    },
    name: {
      de: "Name der Merkliste",
      en: "Collection name",
    },
    uploadFailed: {
      de: "Der Upload ist leider fehlgeschlagen",
      en: "Upload failed",
    },
  };

  @Input() isCart = false;

  @Input() activeTab = "cart";
  @Input() newCollectionName: string;

  @Input() product: Product;

  @Output() tabChange = new EventEmitter<string>();
  @Output() newCollectionNameChanged = new EventEmitter<string>();

  @ViewChild('nav') nav: NgbNav;

  private _userSubscription: Subscription;

  constructor(
    public cartService: CartService,
    public languageService: LanguageService,
    public loginService: LoginService,
    private changeDetectionRef: ChangeDetectorRef,
    public toastService: ToastService
  ) {
  }

  ngOnInit() {
    this._userSubscription = this.loginService.getUser().subscribe(() => {
      if (this.cartService.collectionExists(this.newCollectionName) > -1) {
        this.changeDetectionRef.detectChanges();
        this.activeTab = this.newCollectionName;
        this.uploading = false;
        this.uploadFailed = false;
        this.newCollectionName = "";
        this.collectionFile = null;
      }
    });
  }

  ngOnDestroy() {
    this._userSubscription.unsubscribe();
  }

  navChange($event: NgbNavChangeEvent) {
    this.changeTab($event.nextId);
  }

  changeTab(id: any) {
    this.activeTab = id;
    this.tabChange.emit(id);
  }

  newCollectionNameChange() {
    this.newCollectionNameChanged.emit(this.newCollectionName);
  }

  newCollection(product?: Product) {
    if (this.cartService.allowedCollectionName(this.newCollectionName)) {
      if (product) {
        this.cartService.newCollection(this.newCollectionName, [product]);
      } else {
        this.cartService.newCollection(this.newCollectionName);
      }
      this.changeDetectionRef.detectChanges();
      this.changeTab(this.newCollectionName);
      this.newCollectionName = "";
    }
  }

  getCollectionCount(product: Product, collection: string): number {
    const products = this.cartService.getProducts(collection);

    const index = products.findIndex((p) => p.articleNumber === product.articleNumber);

    if (index === -1) {
      return 0;
    }

    return products[index].amount;
  }

  uploadCollection() {
    if (this.cartService.collectionExists(this.newCollectionName) === -1
      && this.newCollectionName !== ''
      && this.newCollectionName !== undefined) {
      this.uploading = true;
      this.cartService.importCollection(this.collectionFile, this.newCollectionName).subscribe(
        () => {
          this.changeTab(this.newCollectionName);
        },
        err => {
          this.uploading = false;
          this.uploadFailed = true;
        }
      );
    }
  }

  onFileDrop(file: File) {
    this.collectionFile = file;
  }
}
