import {Injectable} from '@angular/core';

declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class UserAgentService {

  constructor() {
  }

  public isIE() {
    const userAgent = navigator.userAgent;
    return userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident/') > -1 || userAgent.indexOf('Edge/') > -1;
  }

  public isSafari() {
    return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
  }

  public isIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  }

  public isPrerender() {
    return (/Prerender/i.test(navigator.userAgent));
  }

  public isLegacy() {
    return this.isIE() || this.isSafari() || this.isIOS();
  }

}
