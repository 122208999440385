import { Injectable } from "@angular/core";
import {
  Cloudinary,
  CloudinaryImage,
  CloudinaryVideo,
} from "@cloudinary/url-gen";
import { dpr } from "@cloudinary/url-gen/actions/delivery";
import { pad } from "@cloudinary/url-gen/actions/resize";
import { cloudinaryConfig } from "../cloudinary-config";
import { HttpClient } from "@angular/common/http";
import { catchError, of, switchMap } from "rxjs";

export interface CloudinaryImages {
  [key: string]: CloudinaryImage;
}

export interface Options {
  height?: number | string;
  width?: number | string;
  aspect?: number;
}

const cl = new Cloudinary(cloudinaryConfig);

@Injectable({
  providedIn: "root",
})
export class CloudinaryService {
  constructor(private http: HttpClient) {}

  buildImages(images, options?: Options): CloudinaryImages {
    const processed: CloudinaryImages = {};

    for (const resource of images.resources) {
      let built;
      const publicId = resource.public_id;
      const imgId = publicId.split("/")[1];

      if (resource.resource_type === "image") {
        built = this.buildImage(publicId, options);
      } else {
        built = this.buildVideo(publicId, options);

        const thumbnail = cl
          .video(publicId + ".jpg")
          .resize(pad().height(100).aspectRatio(1))
          .delivery(dpr(window?.devicePixelRatio ?? 1))
          .quality("auto:eco");

        processed[imgId + ".jpg"] = thumbnail;
      }

      processed[imgId] = built;
    }

    return processed;
  }

  buildImage(publicId: string, options?: Options): CloudinaryImage {
    const resize = this.handleOptions(options);
    return cl
      .image(publicId)
      .resize(resize)
      .delivery(dpr(window?.devicePixelRatio ?? 1))
      .format("auto")
      .quality("auto:eco");
  }

  buildVideo(publicId: string, options?: Options): CloudinaryVideo {
    const resize = this.handleOptions(options);
    return cl
      .video(publicId)
      .resize(resize)
      .delivery(dpr(window?.devicePixelRatio ?? 1))
      .format("auto")
      .quality("auto:eco");
  }

  createPreview(imageId: string) {
    const clImg = this.buildImage(imageId + "/PRE", {
      height: 315,
      width: 400,
    });
    return this.http
      .get(clImg.toURL(), {
        responseType: "blob",
      })
      .pipe(
        switchMap(() => of(clImg)),
        catchError(() => of(null))
      );
  }

  private handleOptions(options: Options) {
    let resize = pad();
    if (options?.height) {
      resize = resize.height(options.height);
    }
    if (options?.width) {
      resize = resize.width(options.width);
    }
    if (options?.aspect) {
      resize = resize.aspectRatio(options.aspect);
    }
    return resize;
  }
}
