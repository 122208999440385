import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild,
} from "@angular/core";
import { Product } from "../../../models/product";
import { AnalyticsService } from "../../analytics.service";
import { LoginService } from "../../user/login/login.service";

@Component({
  selector: "app-product-bar",
  templateUrl: "./product-bar.component.html",
  styleUrls: ["./product-bar.component.scss"],
})
export class ProductBarComponent implements AfterViewInit {
  @ViewChild("items", { static: false }) itemsView;
  @Input() products: Product[];
  @Input() title: string = '';

  private loaded = false;

  constructor(private cd: ChangeDetectorRef, private as: AnalyticsService, private ls: LoginService) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.loaded = true;
      this.as.addViewItemList(this.products, this.title, this.ls.isLoggedIn);
    });
  }

  canScroll(direction = 1) {
    if (this.itemsView && this.loaded) {
      const items = this.itemsView.nativeElement;
      if (
        direction === 1 &&
        items.scrollLeft + items.offsetWidth < items.scrollWidth
      ) {
        return true;
      }
      if (direction === -1 && items.scrollLeft > 0) {
        return true;
      }
    }
    return false;
  }

  scroll(direction = 1) {
    const items = this.itemsView.nativeElement;
    const itemWidth = items.children[0].offsetWidth;
    const scrollInaccuracy = items.scrollLeft % itemWidth;
    const scrollDistance =
      Math.floor((items.offsetWidth * 0.9) / itemWidth) * itemWidth;
    if (direction > 0) {
      this.scrollTo(
        items.scrollLeft,
        items.scrollLeft + scrollDistance - scrollInaccuracy
      );
    } else if (direction < 0) {
      this.scrollTo(
        items.scrollLeft,
        items.scrollLeft - scrollDistance - scrollInaccuracy
      );
    }
  }

  private scrollTo(oldPosition: number, newPosition: number) {
    const items = this.itemsView.nativeElement;
    const initialScrollPos = items.scrollLeft;

    if (oldPosition < newPosition && items.scrollLeft < newPosition) {
      items.scrollLeft += this.ease(oldPosition, newPosition, items.scrollLeft);
    } else if (oldPosition > newPosition && items.scrollLeft > newPosition) {
      items.scrollLeft -= this.ease(oldPosition, newPosition, items.scrollLeft);
    } else {
      return;
    }

    if (initialScrollPos !== items.scrollLeft) {
      setTimeout(() => {
        this.scrollTo(oldPosition, newPosition);
      }, 1);
    } else {
      return;
    }
  }

  private ease(oldPosition, newPosition, currentPosition) {
    const normal =
      ((currentPosition - oldPosition) / (newPosition - oldPosition)) * 2;
    const speed = -Math.pow(1.5 * normal - 1.4, 2) + 3;
    return speed * 5;
  }
}
