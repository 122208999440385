import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { OverlayService } from "../overlay/overlay.service";
import { LanguageService } from "../../../language.service";
import { text } from "./searchbar.i18n";
import { NavbarService } from "../navbar.service";
import { CategoriesService } from "../../categories/categories.service";
import { SearchService } from "../../../search/search.service";

@Component({
  selector: "app-searchbar",
  templateUrl: "./searchbar.component.html",
  styleUrls: ["./searchbar.component.scss"],
})
export class SearchbarComponent {
  public text = text;
  isFocused = false;
  routes = {
    search: {
      de: "suche",
      en: "search",
    },
  };
  private autocompleted = false;

  constructor(
    public ss: SearchService,
    public router: Router,
    public os: OverlayService,
    public ls: LanguageService,
    public ns: NavbarService,
    public cs: CategoriesService
  ) {}

  onBlur() {
    this.ns.deactivateSearch();
    this.isFocused = false;
  }

  onFocus() {
    this.isFocused = true;
  }

  onIcon(searchTerm) {
    this.searchFor(searchTerm);
    this.ns.deactivateSearch();
  }

  onEnter(searchTerm) {
    this.searchFor(searchTerm);
    this.ns.deactivateSearch();
  }

  searchFor(term: string) {
    if (!this.autocompleted) {
      setTimeout(() => {
        this.ns.deactivateSearch();

        this.os.hideCategoriesOverlay();
        this.router.navigate([
          `/${this.ls.rl}${this.routes.search[this.ls.l]}/${term}`,
        ]);
      }, 50);
    }
  }

  onSelect(event) {
    this.ns.deactivateSearch();
    this.autocompleted = true;

    if (event.item.url) {
      this.router.navigate([event.item.url[this.ls.l]]);
    }
  }

  public getSearchTitle(product: any): string {
    const categories = this.cs.getCategoryHierarchy(product.categories);
    return `${product.name} ${categories[0]} ${categories[1]}`;
  }
}
