import { DOCUMENT } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-json-ld",
  templateUrl: "./json-ld.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JsonLdComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  jsonLD: Record<string, any>;

  scriptTag: any;

  constructor(
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  ngOnInit(): void {
    this.createScriptComponent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ("jsonLD" in changes) {
      if (this.scriptTag) {
        this.removeElement();
      }
      this.createScriptComponent();
    }
  }

  ngOnDestroy(): void {
    this.removeElement();
  }

  private createScriptComponent() {
    if (Object.keys(this.jsonLD).length > 0) {
      this.scriptTag = this._renderer2.createElement("script");
      this.scriptTag.type = "application/ld+json";
      this.scriptTag.text = JSON.stringify(this.jsonLD);
      this._renderer2.appendChild(this._document.body, this.scriptTag);
    }
  }

  private removeElement() {
    if (this.scriptTag) {
      this._renderer2.removeChild(this._document.body, this.scriptTag);
      this.scriptTag = null;
    }
  }
}
