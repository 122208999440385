<div class="collection-tabs">
  <ul
    ngbNav
    #nav="ngbNav"
    [(activeId)]="activeTab"
    class="nav-tabs"
    (navChange)="navChange($event)"
    [animation]="false"
  >
    <li
      *ngFor="
        let collection of [
          { name: cartService.CART_COLLECTION, products: cartService.products }
        ].concat(cartService.collections)
      "
      [ngbNavItem]="collection.name"
    >
      <a ngbNavLink>
        <span>{{
          collection.name === cartService.CART_COLLECTION
            ? (text.cart | l)
            : collection.name
        }}</span>
        <div
          class="count"
          *ngIf="product && getCollectionCount(product, collection.name)"
        >
          <div class="pill">
            {{ getCollectionCount(product, collection.name) }}
          </div>
        </div>
      </a>
      <ng-template ngbNavContent>
        <ng-content></ng-content>
      </ng-template>
    </li>
    <li ngbNavItem="new-collection">
      <a ngbNavLink>
        <app-icon [ngbTooltip]="text.addNewCollection | l">add</app-icon>
      </a>
      <ng-template ngbNavContent>
        <div class="new-collection collections">
          <h3 *ngIf="isCart">{{ text.addNewCollection | l }}</h3>
          <div>
            <div class="name-input d-flex input-group mb-3">
              <input
                class="form-control"
                type="text"
                [(ngModel)]="newCollectionName"
                [placeholder]="text.name | l"
                aria-describedby="create-coll-btn"
                (ngModelChange)="newCollectionNameChange()"
              />
              <ngb-progressbar
                *ngIf="uploading"
                [height]="'8px'"
                [value]="100"
                [striped]="true"
                [animated]="true"
              >
              </ngb-progressbar>
              <div class="input-group-append">
                <app-button
                  id="create-coll-btn"
                  [size]="'sm'"
                  *ngIf="isCart && !collectionFile"
                  [text]="text.addCollection | l"
                  (click)="newCollection()"
                  [disabled]="
                    uploading ||
                    cartService.collectionExists(newCollectionName) > -1 ||
                    newCollectionName === '' ||
                    newCollectionName === undefined
                  "
                >
                </app-button>
                <app-button
                  id="create-coll-btn"
                  [size]="'sm'"
                  *ngIf="isCart && collectionFile"
                  [text]="text.addCollection | l"
                  (click)="uploadCollection()"
                  [disabled]="
                    uploading ||
                    cartService.collectionExists(newCollectionName) > -1 ||
                    newCollectionName === '' ||
                    newCollectionName === undefined
                  "
                >
                </app-button>
              </div>
            </div>
          </div>
          <app-file-import
            *ngIf="isCart && (loginService.$user | async)?.can_import"
            [acceptedFileFormats]="'text/csv'"
            (fileDrop)="onFileDrop($event)"
          ></app-file-import>
          <div class="filename">
            <span
              *ngIf="
                isCart &&
                (loginService.$user | async)?.can_import &&
                collectionFile?.name
              "
              >&nbsp;&nbsp;{{ collectionFile.name }}</span
            >
          </div>
          <div
            class="unique-name-alert alert alert-danger"
            *ngIf="
              newCollectionName &&
              (cartService.collectionExists(newCollectionName) > -1 ||
                !cartService.allowedCollectionName(newCollectionName))
            "
          >
            {{ text.uniqueCollectionNames | l }}
          </div>
          <div
            class="unique-name-alert alert alert-danger"
            *ngIf="uploadFailed"
          >
            {{ text.uploadFailed | l }}
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
