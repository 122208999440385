import { Product } from './product';

export class Collection {
  public name: string;
  public products: Product[];

  constructor(name: string, products?: Product[]) {
    this.name = name;
    this.products = products || [];
  }

  /** Return JSON presentation of Collection eliminating underscores. */
  public toJSON() {
    const productsJSON = [];
    for (const product of this.products) {
      productsJSON.push(product);
    }
    return {
      name: this.name,
      products: productsJSON
    };
  }
}
