import { Injectable } from "@angular/core";
import { CategoriesService } from "./shared/categories/categories.service";
import { Product } from "../models/product";
import { environment } from "../environments/environment";

declare let gtag;

interface Item {
  item_id: string;
  item_name: string;
  item_category: string;
  price: number;
  quantity?: number;
}

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  constructor(private categoriesService: CategoriesService) {}

  addPageView(url: string, isAuth: boolean) {
    if (!this.isPrerender()) {
      this.sendEvent(
        "config",
        "G-7QH7ZWSFT4",
        {
          page_path: url,
        },
        isAuth
      );
    }
  }

  addViewItemList(products: Product[], listName: string, isAuth: boolean) {
    if (!this.isPrerender() && products?.length > 0) {
      this.sendEvent(
        "event",
        "view_item_list",
        {
          item_list_id: listName.toLocaleLowerCase().replace(" ", "_"),
          item_list_name: listName,
          items: products.map((p) =>
            this.gtagAddProduct(p, { includeQuantity: false })
          ),
        },
        isAuth
      );
    }
  }

  clickOnItem(product: Product, isAuth: boolean) {
    this.sendEvent(
      "event",
      "select_item",
      {
        items: [this.gtagAddProduct(product, { includeQuantity: false })],
      },
      isAuth
    );
  }

  addItemView(product: Product, isAuth: boolean) {
    if (!this.isPrerender()) {
      this.sendEvent(
        "event",
        "view_item",
        {
          items: [this.gtagAddProduct(product, { includeQuantity: false })],
        },
        isAuth
      );
    }
  }

  addToCart(product: Product) {
    this.sendEvent(
      "event",
      "add_to_cart",
      {
        items: [this.gtagAddProduct(product)],
        value: product.totalPrice,
        currency: "EUR",
      },
      true
    );
  }

  removeFromCart(product: Product) {
    this.sendEvent(
      "event",
      "remove_from_cart",
      {
        items: [this.gtagAddProduct(product)],
        value: product.totalPrice,
        currency: "EUR",
      },
      true
    );
  }

  initiateCheckout(products: Product[], revenue: number) {
    this.sendEvent(
      "event",
      "begin_checkout",
      {
        items: products.map((p) => this.gtagAddProduct(p)),
        currency: "EUR",
        value: revenue,
      },
      true
    );
  }

  purchase(
    products: Product[],
    orderNum: string,
    revenue: number,
    shipping: number
  ) {
    this.sendEvent(
      "event",
      "purchase",
      {
        transaction_id: orderNum,
        value: revenue,
        currency: "EUR",
        shipping: shipping,
        tax: 0,
        items: products.map((p) => this.gtagAddProduct(p)),
      },
      true
    );
  }

  checkoutFailed() {
    this.sendEvent("event", "failed_checkout", {}, true);
  }

  shopVisited(itemId: string, shopId: number, shopName: string) {
    this.sendEvent(
      "event",
      "visit_certified_vendor",
      {
        item_id: itemId,
        shop_id: shopId,
        shop_name: shopName,
      },
      false
    );
  }

  private gtagAddProduct(
    product: Product,
    options = { includeQuantity: true }
  ): Item {
    const item: Item = {
      item_id: product.articleNumber,
      item_name: product.name,
      price: product.totalPrice ?? product.price,
      item_category: this.categoriesService
        .getCategoryHierarchy(product.categories)
        .join("/"),
    };
    if (options.includeQuantity) {
      item.quantity = product.amount ?? 1;
    }

    return item;
  }

  private isPrerender() {
    return /Prerender/i.test(navigator.userAgent);
  }

  private sendEvent(
    type: string,
    eventName: string,
    params: Record<string, any>,
    isAuth: boolean
  ) {
    this.addTrafficType(params);
    this.addCustomerType(params, isAuth);
    gtag(type, eventName, params);
  }

  private addTrafficType(params: Record<string, any>) {
    if (environment.production) {
      params["traffic_type"] = "production";
    } else {
      params["traffic_type"] = "development";
    }
  }

  private addCustomerType(params: Record<string, any>, isAuth: boolean) {
    if (isAuth) {
      params["customer_type"] = "B2B";
    } else {
      params["customer_type"] = "B2C";
    }
  }
}
