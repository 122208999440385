import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LanguageService } from "../../language.service";
import { SelectionOption } from "../../search/search.service";

@Component({
  selector: "app-dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.scss"],
})
export class DropdownComponent {
  @Input() options: SelectionOption[];
  @Input() active: SelectionOption[] | SelectionOption;
  @Input() label: string;
  @Input() multi = false;

  @Output() selectionChanged: EventEmitter<SelectionOption> = new EventEmitter();

  get sortLabel(): string {
    return this.active && !this.multi
      ? this.active[this.languageService.l]
      : this.label;
  }

  constructor(public languageService: LanguageService) {}

  changeSelection(event: SelectionOption): void {
    this.selectionChanged.emit(event);
  }

  isActive(filter: SelectionOption): boolean {
    if (Array.isArray(this.active)) {
      return this.active.some((el) => el.de === filter.de);
    }
    return this.active?.de === filter.de;
  }
}
