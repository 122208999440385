import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AnalyticsService } from "./analytics.service";
import { LoginService } from "./user/login/login.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    public router: Router,
    private as: AnalyticsService,
    private ls: LoginService
  ) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        this.as.addPageView(evt.urlAfterRedirects, this.ls.isLoggedIn);
      }
    });
  }
}
