export const text = {
  new: {
    de: 'Neu',
    en: 'New'
  },
  sale: {
    de: 'Sale',
    en: 'Sale'
  },
  addFav: {
    de: 'Als Favorit hinzufügen',
    en: 'Add to Favorites'
  },
  removeFav: {
    de: 'Aus Favoriten entfernen',
    en: 'Remove from Favorites'
  },
  add: {
    de: 'In den Warenkorb',
    en: 'Add to cart'
  }
};
