import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Meta, MetaDefinition, Title } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class SeoService {
  constructor(
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private dom: Document
  ) {}

  setTitle(title: string) {
    this.title.setTitle(title);
    this.meta.updateTag({ name: "og:title", content: title });
  }

  setMeta(meta: MetaDefinition) {
    this.meta.updateTag(meta);
  }

  setDescription(description: string) {
    this.meta.updateTag({ name: "description", content: description });
    this.meta.updateTag({ name: "og:description", content: description });
  }

  setProductDetails(imgUrl: string, productUrl: string) {
    this.meta.addTags([
      { name: "og:url", content: productUrl },
      { name: "og:image", content: imgUrl },
    ]);
  }

  clearProductMeta() {
    this.meta.removeTag('name="og:url"');
    this.meta.removeTag('name="og:image"');
  }

  setCanonical(url: string) {
    const head = this.dom.getElementsByTagName("head")[0];
    let element: HTMLLinkElement =
      this.dom.querySelector(`link[rel='canonical']`) || null;
    if (element === null) {
      element = this.dom.createElement("link");
      head.appendChild(element);
    }
    element.setAttribute("rel", "canonical");
    element.setAttribute("href", window.location.origin + url);
  }

  removeCanonical() {
    const element: HTMLLinkElement =
      this.dom.querySelector(`link[rel='canonical']`) || null;
    if (element) {
      element.remove();
    }
  }
}
