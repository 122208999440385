import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Product} from '../../models/product';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { LanguageOptions } from '../shared/interfaces/language-options.dto';

export interface SelectionOption extends LanguageOptions {
  value?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  public products: Product[];
  public lastRoute: string;
  public lastSearchTerm: string;
  public scrollPos: number;
  public pageTitle: string;
  public pageDesc: string;
  public deeperCategories;
  public filterData = {
    'width': [],
    'heel': [],
    'material': [],
    'color': []
  };
  public activeFilters = {
    'width': [],
    'heel': [],
    'material': [],
    'color': []
  };
  public requestedPages = 1;
  sortOptions: SelectionOption[] = [
  {
    en: 'Popularity',
    de: 'Beliebtheit',
    value: 'performance',
  },
  {
    en: 'New',
    de: 'Neu',
    value: 'new',
  },
  {
    en: 'Article Number',
    de: 'Artikelnummer',
    value: 'articleNumber',
  },
  ];
  activeSort: SelectionOption = {
    en: 'Popularity',
    de: 'Beliebtheit',
    value: 'performance',
  };

  constructor(private http: HttpClient) {
  }

  restoreDefaults() {
    this.requestedPages = 1;
    this.pageTitle = '';
    this.pageDesc = '';
    this.deeperCategories = [];
  }

  resetFilters() {
    this.filterData = {
      'width': [],
      'heel': [],
      'material': [],
      'color': []
    };
    this.activeFilters = {
      'width': [],
      'heel': [],
      'material': [],
      'color': []
    };
  }

  getAllProducts(limit?: number, offset?: number): Observable<any> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const params = this.getHttpParams(limit, offset);
    const options = {headers: headers, params: params};

    return this.http.get<Product[]>('/api/shop/products', options);
  }

  getCategoryProducts(categories: string[], limit?: number, offset?: number): Observable<any> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const params = this.getHttpParams(limit, offset);
    const options = {headers: headers, params: params};

    return this.http.get<Product[]>('/api/shop/products/' + categories, options);
  }

  getProduct(id: string): Observable<Product> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const options = {headers: headers};

    return this.http.get<Product>('/api/shop/product/' + id, options);
  }

  getProductByIds(ids: string[], limit?: number, offset?: number): Observable<Product[]> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const params = this.getHttpParams(limit, offset);
    const options = {headers: headers, params: params};

    return this.http.get<Product[]>('/api/shop/products-by-id/' + ids.join(','), options);
  }

  search(searchTerm: string, limit?: number, offset?: number): Observable<any> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const params = this.getHttpParams(limit, offset);
    const options = {headers: headers, params: params};

    return this.http.get('/api/shop/search/' + searchTerm, options);
  }

  searchUsers(q: string): Observable<any> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const params = new HttpParams().set('q', q.trim());
    const options = {headers: headers, params: params};

    return this.http.get('/api/users/search', options);
  }

  getHttpParams(limit?: number, offset?: number, favorites?: string[]): HttpParams {
    let params = new HttpParams();

    if (limit) {
      params = params.set('limit', String(limit));
    }
    if (offset) {
      params = params.set('offset', String(offset));
    }
    if (favorites) {
      params = params.set('favorites', JSON.stringify(favorites));
    }

    this.activeFilters.width.forEach(width => {
      params = params.append('width', width.de);
    });
    this.activeFilters.heel.forEach(heel => {
      params = params.append('heel', heel.de);
    });
    this.activeFilters.material.forEach(material => {
      params = params.append('material', material.de);
    });
    this.activeFilters.color.forEach(color => {
      params = params.append('color', color.de);
    });

    if (this.activeSort) {
      params = params.append('sort', this.activeSort.value);
    }

    return params;
  }

  get hasActiveFilters(): boolean {
    return this.activeFilters.width.length > 0 ||
      this.activeFilters.heel.length > 0 ||
      this.activeFilters.material.length > 0 ||
      this.activeFilters.color.length > 0;
  }
}
