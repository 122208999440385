export enum Status {
  PUBLISHED = "published",
  DRAFT = "draft",
  DELETED = "deleted",
}

export enum Page {
  B2B = "b2b",
  B2C = "b2c",
  DANCE_SHOES = "danceshoes",
}

export enum FooterCategory {
  HIDDEN = "hidden",
  COMPANY = "company",
  PRODUCT_INFO = "product_info",
  DANCE_SHOES = "dance_shoes",
}

export enum Language {
  DE = "de",
  EN = "en",
}

export enum Shop {
  DIAMANT = 'Diamant',
  TS24 = 'Tanzschuhe24'
}