import { Component, Input } from "@angular/core";

@Component({
  selector: "app-tile-container",
  templateUrl: "./tile-container.component.html",
  styleUrls: ["./tile-container.component.scss"],
})
export class TileContainerComponent {
  @Input() url: string | null;

  constructor() {}
}
