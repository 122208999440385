import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LandingComponent } from "./landing/landing.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { AuthGuard } from "./user/login/auth.guard";
import { Page } from "./shared/interfaces/directus/enums";
import { StorelocatorComponent } from "./storelocator/storelocator.component";

const routes: Routes = [
  {
    path: "",
    children: [
      // GERMAN
      // basic
      { path: "", component: LandingComponent },

      // ENGLISH
      // basic
      { path: "en", component: LandingComponent },

      { path: "storelocator", component: StorelocatorComponent },

      { path: "en/storelocator", component: StorelocatorComponent },

      {
        path: "tanzschuhe",
        component: LandingComponent,
        data: { tiles: Page.DANCE_SHOES },
      },

      {
        path: "en/dance-shoes",
        component: LandingComponent,
        data: { tiles: Page.DANCE_SHOES },
      },

      // User
      {
        path: "",
        loadChildren: () =>
          import("./user/user.module").then((m) => m.UserModule),
      },

      // Cart
      {
        path: "",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./cart/cart.module").then((m) => m.CartModule),
      },

      // Categories and Products
      {
        path: "",
        loadChildren: () =>
          import("./search/search.module").then((m) => m.SearchModule),
      },

      {
        path: "",
        loadChildren: () =>
          import("./user/user.module").then((m) => m.UserModule),
      },

      { path: "404", component: NotFoundComponent },

      {
        path: "blog",
        loadChildren: () =>
          import("./blog/blog.module").then((m) => m.BlogModule),
      },
      {
        path: "en/blog",
        loadChildren: () =>
          import("./blog/blog.module").then((m) => m.BlogModule),
      },

      {
        path: "",
        loadChildren: () =>
          import("./dynamic-pages/dynamic-pages.module").then(
            (m) => m.DynamicPagesModule
          ),
      },
      {
        path: "en",
        loadChildren: () =>
          import("./dynamic-pages/dynamic-pages.module").then(
            (m) => m.DynamicPagesModule
          ),
      },

      // escape
      { path: "**", component: NotFoundComponent },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
