<div class="container">
  <div class="input-group">
    <input
      #addressInput
      type="text"
      class="form-control address-input"
      autocapitalize="off"
      spellcheck="off"
      placeholder="Adresse"
      aria-label="Adresse"
      aria-describedby="basic-addon2"
      [(ngModel)]="savedAddress"
    />
  </div>
</div>
<div class="container">
  <div class="row location-border mx-0">
    <div class="col-md-5 scroll px-0" [class.minify]="displayAssortment">
      <div
        *ngIf="!searched"
        [ngClass]="{
          'd-flex justify-content-center align-items-center no-location-box':
            !searched
        }"
        class="d-flex justify-content-center"
      >
        <div class="card card-width no-location-wrapper">
          <div class="card-body d-flex justify-content-center">
            <div class="card-title">
              <p class="mb-0 mt-2 no-location-text">
                {{ text.enterAddress | l }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile-nav-toggle">
        <button (click)="switchSelection()" [disabled]="!displayAssortment" class="assortment-button" [class.selected]="!displayAssortment">
          <app-icon>shopping_cart</app-icon>
          {{text.storelist | l}}
        </button>
        <button (click)="switchSelection()" [disabled]="displayAssortment || !assortment" class="assortment-button" [class.disabled]="!assortment" [class.selected]="displayAssortment">
          <app-icon>store</app-icon>
          {{text.assortment | l}}
        </button>
      </div>
      <div class="d-flex justify-content-start" [class.hidden]="displayAssortment" *ngFor="let localStore of stores">
        <div class="card shop-cards">
          <div
            *ngIf="searched"
            class="card-body"
            id="{{ localStore.store.addressNumber }}"
            [ngClass]="{
              'card-focus': localStore.store.addressNumber === selectedStore
            }"
          >
            <div class="d-flex flex-row justify-content-between">
              <h2 class="h5 text-black card-title">{{ localStore.store.shopName }}</h2>
              <div class="d-flex align-items-end flex-column">
                <a
                target="_blank"
                [href]="getMapsUrl(localStore.store.shopName, localStore.store.location.coordinates[1], localStore.store.location.coordinates[0])"
                class="route m-0"
                >
                <div class="circle-icon-container">
                  <app-icon>directions</app-icon>
                </div>
                </a
              >
              <p class="mt-1 text-black distance mb-0">
                {{
                  (localStore.store.distance / 1000 | number : "1.2-2").replace(".", ",")
                }}
                km
              </p>
              </div>
            </div>
            <div class="adjust-paragraph">
              <p class="card-text">{{ localStore.store.zip }} {{ localStore.store.city }}</p>
              <p class="card-text mb-4">{{ localStore.store.streetNum }}</p>
              <div class="d-flex flex-row">
                <p>Tel.:</p>
                <a
                  target="_blank"
                  class="card-text mail-tel ml-1"
                  href="tel:{{ localStore.store.phone }}"
                  >{{ localStore.store.phone }}</a
                >
              </div>
              <div class="d-flex flex-row mb-3">
                <p>E-Mail:</p>
                <a
                  target="_blank"
                  class="card-text mail-tel ml-1"
                  href="mailto:{{ localStore.store.email }}"
                  >{{ localStore.store.email }}</a
                >
              </div>
              <div class="store-buttons-container">
                <a target="_blank" class="website-link" [href]="localStore.store.website">
                <app-icon>public</app-icon>
                {{
                  text.website | l
                }}
                </a>
                <button *ngIf="(displayAssortment && selectedStore !== localStore.store.addressNumber) || !displayAssortment" (click)="getAssortment(localStore.store.addressNumber)" [disabled]="localStore.assortmentCount === 0" [class.disabled]="localStore.assortmentCount === 0" class="assortment-button">
                  <app-icon>store</app-icon>
                  {{ text.showAssortment[languageService.l] + ' (' + localStore.assortmentCount + ')' }}
                </button>
                <button *ngIf="displayAssortment && selectedStore === localStore.store.addressNumber" (click)="showMap()" class="assortment-button">
                  <app-icon>location_on</app-icon>
                  {{ text.showMap | l }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-7 px-0">
      <div *ngIf="!options && !displayAssortment" class="location-message-container">
        <h6 *ngIf="options === null" class="location-error-message">
          {{ text.locationError | l }}
        </h6>
      </div>
      <div class="scroll assortment-container" *ngIf="displayAssortment">
        <div class="product"
        *ngFor="let product of assortment">
     <app-item [content]="product"></app-item>
      </div>
      </div>
      <google-map
        *ngIf="options && !displayAssortment"
        width="100%"
        height="100%"
        [options]="options"
      >
        <map-marker
          *ngIf="zoom !== 2"
          icon="/assets/maps/blue-pin.png"
          [position]="options.center"
        ></map-marker>
        <map-marker
          (mapClick)="selectMarker(localStore.store)"
          *ngFor="let localStore of stores"
          [position]="{
            lat: localStore.store.location.coordinates[1],
            lng: localStore.store.location.coordinates[0]
          }"
        ></map-marker>
      </google-map>
    </div>
  </div>
</div>
