export const text = {
  amount: {
    de: 'Menge',
    en: 'Amount'
  },
  price: {
    de: 'Preis',
    en: 'Price'
  },
  itemPrice: {
    de: 'Stückpreis',
    en: 'unit price'
  },
  addToCart: {
    de: 'In den Warenkorb',
    en: 'Add to cart'
  },
  addToCollection: {
    de: 'In diese Merkliste',
    en: 'Add to this collection'
  },
  quickOrder: {
    de: 'Schnellbestellung',
    en: 'Quick Order'
  },
  noHits: {
    de: 'Keine Ergebnisse',
    en: 'No results'
  },
};

export const routes = {
  article: {
    de: '/artikel/',
    en: '/en/article/'
  },
  search: {
    de: 'suche',
    en: 'search'
  }
};
