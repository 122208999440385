import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DomSanitizer } from "@angular/platform-browser";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../environments/environment";
import { LanguageService } from "./language.service";
import {
  BlogPost,
  InfoPage,
  LandingPageTile,
} from "./shared/interfaces/directus/collections";
import { QueryOptions } from "./shared/interfaces/directus/query-options.interface";
import { Page, Shop, Status } from "./shared/interfaces/directus/enums";

@Injectable({
  providedIn: "root",
})
export class DirectusService {
  private POST_LIMIT = 5;

  constructor(
    private languageService: LanguageService,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) {}

  private filterQueryGenerator(filterObject) {
    const query: {
      [key: string]: string;
    } = {};

    for (const field in filterObject) {
      const operator = Object.keys(filterObject[field])[0];
      const key = `filter[${field}][${operator}]`;
      query[key] = filterObject[field][operator];
    }

    return query;
  }

  private loadContent<T>(
    collection: string,
    options?: QueryOptions<T>
  ): Observable<any> {
    const requestParams: any = { fields: "*.*" };

    if (options?.filter) {
      const filter = this.filterQueryGenerator(options.filter);

      for (const key in filter) {
        requestParams[key] = filter[key];
      }
    }
    if (options?.sort) {
      requestParams.sort = options.sort;
    }
    if (options?.offset) {
      requestParams.offset = options.offset;
    }
    if (options?.limit) {
      requestParams.limit = options.limit;
    }

    return this.http
      .get(`${environment.directus_url}/items/${collection}`, {
        params: requestParams,
      })
      .pipe(
        map((res: any) => res.data),
        // convert translations array to object
        map((data: any[]) => {
          return data.map((dataObj) => {
            const translations: any = {};

            for (const translation of dataObj.translations) {
              translations[translation.language] = translation;
            }
            return { ...dataObj, translations };
          });
        }),
        map((data: any[]) => {
          return data.map((item) => {
            const translation = item.translations[this.languageService.l] ?? item.translations["de"];
            translation.slugs = {
              de: item.translations["de"]?.slug,
              en: item.translations["en"]?.slug,
            };
            delete translation.id;
            delete translation.language;
            delete translation.created_on;
            delete translation.modified_on;
            delete translation.modified_by;

            delete item.translations;

            const translatedItem = {
              ...item,
              ...translation,
              url: translation.url ?? null,
            };
            translatedItem.content = translation.content
              ? this.sanitizer.bypassSecurityTrustHtml(translation.content)
              : null;
            translatedItem.image = item.image ? item.image.data.full_url : null;
            return translatedItem;
          });
        })
      );
  }

  loadLandingPageTiles(page: Page = Page.B2B): Observable<LandingPageTile[]> {
    return this.loadContent<LandingPageTile>("landing_page_tile", {
      filter: { page: { eq: page } },
    });
  }

  loadBlogPosts(offset?: number): Observable<BlogPost[]> {
    return this.loadContent<BlogPost>("blog_post", {
      sort: ["-publication_date"],
      filter: {
        shop: {
          eq: Shop.DIAMANT,
        },
        status: {
          eq: Status.PUBLISHED,
        },
      },
      limit: this.POST_LIMIT,
      offset: offset ?? 0,
    });
  }

  loadBlogPost(slug: string): Observable<BlogPost> {
    return this.loadContent<any>("blog_post", {
      filter: {
        "translations.slug": { eq: slug },
      },
    }).pipe(map((p) => p[0]));
  }

  loadDynamicPages(): Observable<InfoPage[]> {
    return this.loadContent("info_page");
  }

  loadFooterHierarchy(): Observable<any> {
    return this.loadDynamicPages().pipe(
      map((res) => {
        const categories = {
          company: [],
          product_info: [],
          dance_shoes: [],
        };
        for (const c of Object.keys(categories)) {
          categories[c] = res.filter((r) => r.footer_category === c);
        }
        return categories;
      })
    );
  }

  loadDynamicPageHierarchy(): Observable<InfoPage[]> {
    return this.loadDynamicPages().pipe(
      map((data) => {
        data.forEach((item) => {
          if (item.parent) {
            const parent = data.find(
              (pb) => pb.category_name === item.parent.category_name
            );
            if (parent.children) {
              parent.children.push(item);
            } else {
              parent.children = [item];
            }
          }
        });
        return data.filter((p) => !p.parent && !p.overview_hidden);
      })
    );
  }

  loadDynamicPage(slug: string): Observable<InfoPage> {
    return this.loadContent("info_page", {
      filter: {
        "translations.slug": { eq: slug },
      },
    }).pipe(map((p) => p[0]));
  }
}
