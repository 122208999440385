<div class="order">
  <app-collection-tabs
    #collectionTabs
    *ngIf="showTabs"
    [activeTab]="activeTab"
    (tabChange)="beforeChange($event)"
    [product]="product"
    [newCollectionName]="newCollectionName"
    (newCollectionNameChanged)="newCollectionName = $event"
  >
  </app-collection-tabs>
  <div class="size-select mb-2">
    <app-size-select
      #sizeSelect
      [product]="product"
      [collectionName]="activeTab"
      (submitted)="add(product, activeTab)"
      [isProductDetailPage]="isProductDetailPage"
    ></app-size-select>
  </div>
  <app-order-button
    [cartProduct]="cartProduct"
    [product]="product"
    [collectionName]="activeTab"
    [newCollectionName]="newCollectionName"
    (add)="add($event, activeTab)"
  ></app-order-button>
</div>
