import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'amount'
})
export class AmountPipe implements PipeTransform {

  transform(value: any, args?: any): number | undefined {
    if (value) {
      const input = value.toString().replace(/[^0-9.]/g, '');
      const returnValue = parseInt(input, 0);
      return !isNaN(returnValue) ? Number(returnValue) : Number(0);
    }
    return undefined;
  }
}

