import {AfterContentChecked, Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[appHighlightWidthProp]'
})
export class HighlightWidthPropDirective implements AfterContentChecked {

  constructor(private el: ElementRef) {
  }

  ngAfterContentChecked() {
    const value = this.el.nativeElement.innerHTML;
    if (value === 'Normal') {
      this.el.nativeElement.innerHTML = 'Normalweit';
    }
    if (value === 'Schmal' || value === 'narrow') {
      this.el.nativeElement.style.color = 'rgb(79,169,40)';
    }
    if (value === 'Bequem' || value === 'comfortable' || value === 'Bequem weit' || value === 'comfortable wide') {
      this.el.nativeElement.style.color = 'rgb(164,29,35)';
    }
  }
}
